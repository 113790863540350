import {ApplicationForStudentSelf} from '../models/ApplicationX';
import StudentVoteX from '../models/StudentVoteX';
import {ApplicationStateEnum, LIPSUM_50, PageRoutingEnum, StudentRoleEnum} from '../enums/Enums';
import {StudentX} from '../models/StudentX';
import Application from '../models/Application';
import {BatchX} from '../models/BatchX';

export function disableScroll(isTrue: boolean): void {
  const scrollbarW = window.innerWidth - document.body.getBoundingClientRect().right;
  if (isTrue) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.paddingRight = `${scrollbarW}px`;
  } else {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    document.body.style.paddingRight = '0px';
  }
}

export function debounce(fn: () => void, dur?: number): () => void {
  const time = dur || 0; // 10 by default if no param
  let timer: NodeJS.Timeout;
  return () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(fn, time);
  };
}

export function stringifyDate(time: number | Date, options?: Intl.DateTimeFormatOptions): string {
  let dateObject: Date;

  if (typeof time === 'number')  {
    dateObject = new Date(time);
  } else {
    dateObject = time;
  }

  return dateObject.toLocaleString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'shortGeneric',
    ...options,
  });
}

export function convertApplicationToVote(application: Partial<Application>, batch: Partial<BatchX>): StudentVoteX {
  const studentX: StudentX = {
    id: '',
    firstName: application?.firstName || 'First',
    lastName: application?.lastName || 'Last',
    faculty: application?.faculty || 'Faculty',
  }

  const applicationX: ApplicationForStudentSelf = {
    id: '',
    batch: {
      id: batch?.id || -1,
      batchNumber: batch?.batchNumber || '0',
      semester: batch?.semester || 'Preview',
      registrationStart: batch?.registrationStart || 0,
      registrationEnd: batch?.registrationEnd || 0,
      voteStart: batch?.voteStart || 0,
      voteEnd: batch?.voteEnd || 0,
      acceptDeadline: batch?.acceptDeadline || 0,
    },
    isVotesLocked: false,
    outgoingStudentVotes: [],
    state: ApplicationStateEnum.INCOMPLETE,
    student: studentX,
    imgUrl: studentX.imgUrl,
    cvFilename: application.cvFilename,
    applicationFilename: application.applicationFilename,
    applicationLink: application.applicationLink,
    primaryRole: application?.primaryRole || StudentRoleEnum.UNDEFINED,
    secondaryRole: application?.secondaryRole || StudentRoleEnum.UNDEFINED,
    motivationText: application?.motivationText || LIPSUM_50,
  };

  return {
    id: '',
    batchId: 0,
    senderId: '',
    sender: applicationX,
    recipient: applicationX,
    recipientId: '',
    point: 1,
    initPoint: 1,
    lockedDt: 1,
  };
}

/**
 * Returns the magicUrl from the pathname if exists.
 */
export function inferToken(): string | undefined {
  const path = window.location.pathname.split('/');
  const roles = [PageRoutingEnum.STUDENT_OUTLET, PageRoutingEnum.COACH_OUTLET];
  if (roles.includes(path[1] as PageRoutingEnum.STUDENT_OUTLET | PageRoutingEnum.COACH_OUTLET)) {
    return `${path[1]}/${path[2]}`;
  }
  return undefined;
}