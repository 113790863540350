import { useEffect, useRef } from "react";
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {RootState, AppDispatch} from '../store/store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usePrevious = (value: any) => {
  // https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
  const prevChildrenRef = useRef();
  useEffect(() => {
    prevChildrenRef.current = value;
  }, [value]);
  return prevChildrenRef.current;
};

