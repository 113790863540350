import React, {useState} from 'react';
import FillHeightWrapper from '../../components/FillHeightWrapper';
import {TextField} from '@mui/material';
import {SubscribeForm} from '../../models/SubscribeForm';
import PaddedButton from '../../components/PaddedButton';
import {gql, useMutation} from '@apollo/client';
import {useAppDispatch} from '../../hooks/hooks';
import {updateBlockScreen, updateSnackbar} from '../../store/appSlice';

type State = {
  form?: Partial<SubscribeForm>,
  validationErrors?: { [key in keyof SubscribeForm]?: string },
  hideForm?: boolean,
}

export default function PublicUnsubscribe() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({});
  const {form, validationErrors, hideForm} = state;
  const [postMutation] = useMutation(gql`
    mutation Mutation ($email: String) {
      newsletterUnsubscribe (email: $email)
    }
  `, {variables: {email: form?.email}});

  const updateForm = (obj: Partial<SubscribeForm>) => {
    const keys = Object.keys(obj) as (keyof SubscribeForm)[];
    const veCopy = {...validationErrors};
    for (const k of keys) delete veCopy[k];
    setState({...state, form: {...form, ...obj}, validationErrors: veCopy});
  };

  const submitForm = () => {
    dispatch(updateBlockScreen(true));
    postMutation()
      .then(() => setState({...state, hideForm: true}))
      .catch((e) => {
        dispatch(updateSnackbar({message: e.graphQLErrors[0]?.message || e.message, isError: true}));
        setState({...state, validationErrors: e.graphQLErrors[0]?.extensions?.validationErrors});
      })
      .finally(() => dispatch(updateBlockScreen(false)));
  };

  return (
    <FillHeightWrapper verticalWidthUnwrap centerVertically>
      <div className={'flex flex-col gap-4 text-center max-w-[500px]'}>
        {
          !hideForm &&
          <>
            <div>
              <h1>Unsubscribe</h1>
              <p>Please enter your email address to stop receiving TMS updates.</p>
            </div>
            <div className={'flex gap-4 flex-wrap'}>
              <TextField value={form?.email || ''} label={'Email address'}
                         variant={'filled'} className={'flex-grow'} error={!!validationErrors?.email}
                         helperText={validationErrors?.email} type={'email'} autoFocus
                         onKeyDown={(e) => e.key === 'Enter' && submitForm()}
                         onChange={(e) => updateForm({email: e.target.value.toLowerCase().replaceAll(' ', '')})}/>
              <div className={'flex-grow sm:pb-6 sm:flex-grow-0'}>
                <PaddedButton label={'Unsubscribe'} growOnMobile onClick={() => submitForm()}/>
              </div>
            </div>
          </>
        }
        {
          hideForm &&
          <div>
            <h1>We've received your request</h1>
            <p>If you are registered with our database, you will receive an email to confirm that <strong>{form?.email}</strong> have been unsubscribed from our mailing list shortly.</p>
          </div>
        }
      </div>
    </FillHeightWrapper>
  );
}