import React, {useEffect, useRef, useState} from 'react';
import WidthWrapper from "./WidthWrapper";
import ImageWrapper from "./ImageWrapper";
import {IconButton} from '@mui/material';
import {Close, Menu} from '@mui/icons-material';
import {debounce, disableScroll} from '../helpers/helper';

type HeaderProps = {
  children?: JSX.Element,
  headerBackground?: string,
  menuBackground?: string,
  headerFontColor?: string,
  menuFontColor?: string,
  to?: string,
  navItems?: JSX.Element,
}

export default function Header({children, headerBackground, menuBackground, headerFontColor, menuFontColor, to, navItems}: HeaderProps) {
  const headerDOM = useRef<HTMLDivElement>(null);
  const [headerH, setHeaderH] = useState<number>(0);
  const [menuH, setMenuH] = useState<number>(0);
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);
  const [menuContentScrollBehavior, setMenuContentScrollBehavior] = useState<'auto' | 'hidden'>('auto');

  useEffect(() => {
    disableScroll(isMenuExpanded);
    if (isMenuExpanded) {
      setMenuContentScrollBehavior('hidden');
      setMenuH(window.innerHeight - headerH + 1);
      setTimeout(() => {
        setMenuContentScrollBehavior('auto');
      }, 300);
    } else {
      setMenuContentScrollBehavior('hidden');
      setMenuH(0);
    }
  }, [isMenuExpanded, headerH]);

  useEffect(() => {
    const measureBounds = () => {
      setIsMenuExpanded(false);
      setHeaderH(headerDOM.current?.getBoundingClientRect().bottom || headerH);
    }

    measureBounds();

    window.addEventListener('resize', debounce(measureBounds));
    return () => window.removeEventListener('resize', debounce(measureBounds));
  }, [headerH]);
  return (
    <div className={'w-full sticky left-0 top-0 z-40 text-white'} ref={headerDOM} id={'header'}
         style={{backgroundColor: headerBackground || '#000', color: headerFontColor || '#fff'}}>
      <WidthWrapper className={'py-2 md:py-2 h-full'}>
        <div className={'flex flex-wrap gap-2 justify-start items-center'}>
          <span className={'flex-grow lg:flex-grow-0 flex py-4'}>
            <a href={to || '/'} className={'cursor-pointer'}>
              <ImageWrapper src={'/images/logo_white_text.png'}
                            alt={'Think. Make. Start.'}
                            className={'h-6 m-auto lg:m-0'}/>
            </a>
          </span>
          <span className={'hidden lg:block flex-grow font-medium'}>
            {children}
          </span>
          {
            navItems && <span className={'lg:hidden'} onClick={() => setIsMenuExpanded(!isMenuExpanded)}>
            <IconButton size={'large'} sx={{color: '#fff'}}>
              {
                !isMenuExpanded ? <Menu/> : <Close/>
              }
            </IconButton>
          </span>
          }
        </div>
      </WidthWrapper>
      {
        navItems && <div
          className={'lg:hidden fixed flex items-center justify-center transition-height duration-300 text-white overflow-hidden'}
          style={{top: `${headerH}px`, height: `${menuH}px`, background: menuBackground || headerBackground || '#333', color: menuFontColor || headerFontColor || '#fff', width: `${document.body.getBoundingClientRect().right - Number.parseInt(document.body.style.paddingRight.slice(0, -2))}px`}}
          onClick={() => setIsMenuExpanded(false)}>
          <div
            className={'flex flex-col items-center justify-center gap-6 text-center w-full max-h-full pt-10 pb-32 px-4 lg:px-6 text-3xl overflow-y-auto overflow-x-hidden'}
            style={{overflowY: menuContentScrollBehavior}}>
            {
              children && <div className={'text-xl mb-6'}>{children}</div>
            }
            {navItems}
          </div>
        </div>
      }
    </div>
  );
}