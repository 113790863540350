import React from 'react';
import {Checkbox} from '@mui/material';

type formCheckboxProps = {
  checked?: boolean,
  label: string | JSX.Element,
  onClick: () => void,
  disabled?: boolean
}

export default function TmsFormCheckbox({checked, onClick, label, disabled}: formCheckboxProps) {
  return (
    <div className={'flex gap-2 items-start'}>
      <div className={'text-2xl'}>
        <Checkbox checked={checked || false} onClick={() => onClick()} disabled={disabled}/>
      </div>
      <button className={'mt-2 text-left'} onClick={() => onClick()} disabled={disabled}>
        {label}
      </button>
    </div>
  );
}