import React, {useState} from "react";
import TypeInChallengeDialog from "../TypeInChallengeDialog";
import {FormPropLabelEnum} from "../../enums/Enums";
import {StudentForStudentSelfWithApplicationsAsForm} from "../../models/StudentX";
import {ApplicationForStudentSelfAsFormWithResponse} from "../../models/ApplicationX";
import ApplicationMissingFileUploader from "./ApplicationMissingFileUploader";
import FieldWrapper from "../applicationForm/FieldWrapper";
import {AccountCircle, ArrowForward, AttachFile, LockPerson} from "@mui/icons-material";
import FileUploader from "../applicationForm/FileUploader";
import PaddedButton from "../PaddedButton";
import ImageWrapper from "../ImageWrapper";
import {gql, MutationFunctionOptions, useMutation} from "@apollo/client";
import {updateBlockScreen, updateSnackbar} from "../../store/appSlice";
import {useAppDispatch} from "../../hooks/hooks";


interface Props {
     fileType: string;
     student: Partial<StudentForStudentSelfWithApplicationsAsForm>;
     application?: Partial<ApplicationForStudentSelfAsFormWithResponse>;
}

type State = {
    typeInChallengeDialogIsVisible?: boolean,
}


export default function ApplicationMissingFile(props: Props) {
    const {fileType, student, application} = props;

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [file, setFile] = useState<File[]>([]);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);

    const [state, setState] = useState<State>({});
    const {typeInChallengeDialogIsVisible} = state;
    const dispatch = useAppDispatch();

    let newStudent = {...student, 'applications':undefined}
    newStudent.imgUrl = student.imgUrl? student.imgUrl : ''


    const [validateFile] = useMutation(gql`
        mutation Mutation ($fileName: String!, $fileSize: Int!, $fileType: String!) {
        validateFileBeforeUpload(fileName: $fileName, fileSize: $fileSize, fileType: $fileType)
        }
    `);

    const testFile = async (options: MutationFunctionOptions) => {
        let error :string|null = null ;
        dispatch(updateBlockScreen(true));
        await validateFile(options)
            .catch((e:{errorCode: number, message?: string}) => {
                error = e.message?e.message:null;
                dispatch(updateSnackbar({message: e.message, isError: true}))
            })
            .finally(() => {
                dispatch(updateBlockScreen(false));
            });
        if(error === null) {
            setErrorMessage(null);
            if (student?.lrzId || student?.email) {
                setState({...state, typeInChallengeDialogIsVisible: true});
            } else {
                setIsUploading(true);
            }
        }
        else {
            setErrorMessage(error);
        }
    };

    return (<div>
        {
            isUploading && <ApplicationMissingFileUploader application={application} fileType={fileType} student={newStudent} file={file[0]}/>
        }
        {
            !isUploading &&
            <div>
                <p className={'opacity-80'}>Document is missing</p>
                {
                    fileType === 'profile' &&
                    <div className={'flex flex-col gap-4 w-48'}>
                        <div className={'w-48'}>
                            <ImageWrapper src={file.length>0 ? URL.createObjectURL(file[0]) : 'error'}
                                          alt={`Profile picture for `}
                                          className={'w-full h-full bg-gray-50 aspect-[1/1] rounded-lg text-5xl border'}
                                          icon={<AccountCircle/>}/>
                        </div>
                        <FileUploader icon={<AttachFile/>} fileLimit={1} accept={'.jpg,.jpeg,.png'}
                                                           helperText={''}
                                                           error={false}
                                                           files={file}
                                                           onFileChange={(files) => setFile(files)}
                                                           onDeleteSingleFile={() => setFile([])}/>
                        <div className={'text-red-500'}>{errorMessage}</div>
                        <FieldWrapper className={'mt-2'} >
                            <PaddedButton label={'Submit'} icon={<ArrowForward/>} disabled={file.length===0} className={'w-full'}
                                          trailingIcon
                                          onClick={() => {
                                              testFile({
                                                  variables: {
                                                      fileType: fileType,
                                                      fileName: file[0].name,
                                                      fileSize: file[0].size,
                                                  }
                                              });
                                          }}
                            />
                        </FieldWrapper>
                    </div>
                }
                {(fileType === 'cv' || fileType === 'supplement') &&
                    <div>
                        <FieldWrapper
                            description={<><LockPerson/> Please select .pdf files only. Size of file should not exceed
                                15MB.</>}>
                            <FileUploader icon={<AttachFile/>} fileLimit={1} accept={'.pdf'}
                                          helperText={''}
                                          error={false}
                                          files={file}
                                          onFileChange={(files) => setFile(files)}
                                          onDeleteSingleFile={() => setFile([])}/>
                        </FieldWrapper>
                        <div className={'text-red-500'}>{errorMessage}</div>
                        <FieldWrapper className={'mt-2'}>
                            <PaddedButton label={'Submit'} icon={<ArrowForward/>} disabled={file.length === 0}
                                          className={'w-full'}
                                          trailingIcon
                                          onClick={() => {
                                              testFile({
                                                  variables: {
                                                      fileType: fileType,
                                                      fileName: file[0].name,
                                                      fileSize: file[0].size,
                                                  }
                                              });
                                          }}
                            />
                        </FieldWrapper>
                    </div>
                }
            </div>
        }
        {
            <TypeInChallengeDialog isVisible={!!typeInChallengeDialogIsVisible}
                                   targetValue={student?.lrzId || student?.email || ''}
                                   prompt={`Please re-enter your ${student?.lrzId  ? 'LRZ/TUM ID' : 'email address'} to proceed:`}
                                   fieldName={student?.lrzId  ? FormPropLabelEnum.lrzId : FormPropLabelEnum.email}
                                   onClose={() => setState({...state, typeInChallengeDialogIsVisible: false})}
                                   onChallengePassed={()=>setIsUploading(true)}/>
        }
    </div>)
}