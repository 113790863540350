import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {updatePageTitle} from '../../../store/appSlice';
import {gql, useQuery} from '@apollo/client';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import BatchSelector from '../../../components/BatchSelector';
import {StudentForStudentSelfWithApplications} from '../../../models/StudentX';
import {BatchX} from '../../../models/BatchX';
import StudentVoting from './StudentVoting';

type State = {
  typeInChallengeDialogIsVisible?: boolean,
  student?: StudentForStudentSelfWithApplications,
  selectedApplicationId?: string,
  selectedBatch?: BatchX,
  lastFetch: Date,
}

export default function StudentVotingBatchSelector() {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({lastFetch: new Date()});
  const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));
  const {student, selectedBatch} = state;

  const QUERY = gql`
    query Query ($magicUrl: String!) {
      studentGetStudent (magicUrl: $magicUrl) {
        id
        applications {
          id
          batch {
            id
            batchNumber
            semester
            voteStart
            voteEnd
          }
        }
      }
    } 
  `;

  const {loading, error, data} = useQuery(QUERY, {variables: {magicUrl}, fetchPolicy: 'network-only'});

  useEffect(() => {
    updateState({
      student: data?.studentGetStudent,
      selectedBatch: data?.studentGetStudent?.applications[0]?.batch,
      lastFetch: new Date(),
    });
  }, [data]);

  useEffect(() => {
    dispatch(updatePageTitle('Peer Voting'));
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col gap-4'}>
        {
          student && selectedBatch &&
          <>
            <>
              {
                student.applications.length > 1 &&
                <BatchSelector selectedBatch={selectedBatch}
                               batches={student.applications.map((a) => a.batch)}
                               onChange={(e) => updateState({selectedBatch: student.applications.find((a) => a.batch.id === Number.parseInt(e.target.value))?.batch})}/>
              }
              {
                student.applications.length === 1 && <h1>Batch {selectedBatch.batchNumber} ({selectedBatch.semester})</h1>
              }
            </>
            <StudentVoting selectedBatch={selectedBatch}/>
          </>
        }
        {
          student && student.applications.length === 0 &&
          <h2>There aren't any applications associated with you at the moment</h2>
        }
      </div>
    </QueryHandlerBase>
  );
}