import React, {useState} from 'react';
import PaddedButton from '../PaddedButton';
import {Email} from '@mui/icons-material';
import BroadcastDialog from './BroadcastDialog';
import {ApplicationStateEnum} from '../../enums/Enums';
import {BatchX} from '../../models/BatchX';

type broadcastButtonProps = {
  stateFilter?: {[key in ApplicationStateEnum]?: boolean},
  batch: BatchX,
  coachEmail: string,
}

type State = {
  showModal?: boolean,
}

export default function BroadcastButton({stateFilter, batch, coachEmail}: broadcastButtonProps) {
  const [state, setState] = useState<State>({});
  const {showModal} = state;

  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };

  return (
    <>
      <PaddedButton label={'Bulk message'} icon={<Email/>} trailingIcon onClick={() => updateState({showModal: !showModal})}/>
      {
        showModal &&
        <BroadcastDialog isVisible={showModal} onClose={() => updateState({showModal: !showModal})} stateFilter={stateFilter} batch={batch} coachEmail={coachEmail}/>
      }
    </>
  );
}