import React, {useEffect, useState} from 'react';
import {stringifyDate} from '../../../helpers/helper';
import FieldWrapper from '../../../components/applicationForm/FieldWrapper';
import FormButtonWrapper from '../../../components/applicationForm/FormButtonWrapper';
import StudentVoteButton from '../../../components/studentVoteButton/StudentVoteButton';
import PaddedButton from '../../../components/PaddedButton';
import {Done} from '@mui/icons-material';
import TypeInChallengeDialog from '../../../components/TypeInChallengeDialog';
import {gql, useMutation, useQuery} from '@apollo/client';
import {BatchX} from '../../../models/BatchX';
import {StudentForStudentSelfWithApplications} from '../../../models/StudentX';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import {updateBlockScreen, updateSnackbar} from '../../../store/appSlice';

type studentVotingProps = {
  selectedBatch?: BatchX,
}

type State = {
  student?: StudentForStudentSelfWithApplications,
  isVotingPeriodOpen: boolean,
  lastFetch?: Date,
  typeInChallengeDialogIsVisible?: boolean,
}

export default function StudentVoting({selectedBatch}: studentVotingProps) {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({
    isVotingPeriodOpen: new Date() > new Date(selectedBatch?.voteStart || 0),
  });
  const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));
  const {student, isVotingPeriodOpen, lastFetch, typeInChallengeDialogIsVisible} = state;

  const {loading, data, error, refetch} = useQuery(gql`
    query Query ($magicUrl: String!, $batchId: Int!) {
      studentGetStudent (magicUrl: $magicUrl) {
        id
        email
        applications (batchId: $batchId)  {
          id
          isVotesLocked
          outgoingStudentVotes {
            id
            recipient {
              id
              primaryRole
              secondaryRole
              motivationText
              applicationLink
              applicationFilename
              student {
                id
                firstName
                lastName
                imgUrl
                faculty
              }
            }
            point
            initPoint
          }
        }
      }
    }
  `, {variables: {magicUrl, batchId: selectedBatch?.id || 0}});

  const [postMutation] = useMutation(gql`
    mutation Mutation ($magicUrl: String!, $batchId: Int!, $lastFetch: String!) {
      studentCommitStudentVote (magicUrl: $magicUrl, batchId: $batchId, lastFetch: $lastFetch)
    }
  `, {variables: {magicUrl, batchId: selectedBatch?.id || 0, lastFetch: (lastFetch || new Date()).toISOString()}});

  const lockVotes = () => {
    dispatch(updateBlockScreen(true));
    postMutation()
      .then(() => {
        dispatch(updateSnackbar({message: 'Votes have been locked'}));
        refetch().then();
      })
      .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
      .finally(() => dispatch(updateBlockScreen(false)));
  }

  const checkforChangedVotes = () => {
    let studentVotes = data.studentGetStudent.applications[0].outgoingStudentVotes
    let isUnchanged = true

    //console.log(studentVotes)
    for (let vote of studentVotes) {
      isUnchanged = isUnchanged && (vote.point === vote.initPoint);
    }

    if(isUnchanged)
      return 'You did not change any order of the applicants, are your sure that this is the correct order? \n if so please enter your email address to proceed:';

    else return 'Please enter your email address to proceed:';
  }

  useEffect(() => {
    updateState({student: data?.studentGetStudent, lastFetch: new Date()});
  }, [data]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <>
        {
          student &&
            <>
              {
                isVotingPeriodOpen !== undefined && !isVotingPeriodOpen &&
                  <p>The voting period starts on {stringifyDate(selectedBatch?.voteStart || 0)}.</p>
              }
              {
                isVotingPeriodOpen && student.applications[0].outgoingStudentVotes.length === 0 &&
                  <p>There were no voting tasks found assigned to you</p>
              }
              {
                isVotingPeriodOpen && student.applications[0].outgoingStudentVotes.length > 0 &&
                  <>
                      <h2>Your votes</h2>
                    {
                      !student.applications[0].isVotesLocked &&
                        <FieldWrapper>
                            <p>Please submit your votes before {stringifyDate(selectedBatch?.voteEnd || 0, {second: '2-digit'})}.</p>
                        </FieldWrapper>
                    }
                      <FieldWrapper description={'' +
                        `${student.applications[0].isVotesLocked ? '' : 'Use the arrows to re-arrange your selection. Your selection is automatically saved after every change.'}`}>
                          <FormButtonWrapper>
                              <>
                                {
                                  student.applications[0].outgoingStudentVotes.map((vote) => <StudentVoteButton key={vote.id} vote={vote} arraySize={student.applications[0].outgoingStudentVotes?.length} onTriggerRefetch={refetch} lastFetch={lastFetch || new Date()} isVotesLocked={student.applications[0].isVotesLocked}/>)
                                }
                              </>
                          </FormButtonWrapper>
                      </FieldWrapper>
                      <FieldWrapper className={'mt-2'} description={student.applications[0].isVotesLocked ? 'Your votes have been submitted. You may no longer make changes to your votes.' : <><strong>Important:</strong> You will not be able to make changes to your votes after you lock them.</>}>
                          <PaddedButton label={student.applications[0].isVotesLocked ? 'Submitted' : 'Submit votes'}
                                        icon={student.applications[0].isVotesLocked ? <Done/> : <></>}
                                        disabled={student.applications[0].isVotesLocked}
                                        onClick={() => {
                                          setState({...state, typeInChallengeDialogIsVisible: true});
                                        }} className={'w-full'}/>
                      </FieldWrapper>
                  </>
              }
                <TypeInChallengeDialog isVisible={!!typeInChallengeDialogIsVisible} prompt={checkforChangedVotes()}
                                       onClose={() => setState({...state, typeInChallengeDialogIsVisible: false})}
                                       onChallengePassed={lockVotes} fieldName={'Email address'} targetValue={student?.email || ''}/>
            </>
        }
      </>
    </QueryHandlerBase>
  );
}