import React from 'react';
import NavItem from './NavItem';

type sidebarOptionProps = {
  label: string | JSX.Element,
  isActive?: boolean,
  onClick?: () => void,
}

export default function SidebarOption({label, isActive, onClick}: sidebarOptionProps) {
  return (
    <>
      <NavItem isActive={isActive} label={label} onClick={onClick}/>
      <button className={'hidden lg:block px-4 py-2 bg-black bg-opacity-0 hover:bg-opacity-5 cursor-pointer w-full text-left w-[200px] max-w-[200px] rounded-md ' +
        `${isActive ? 'font-bold text-tms-blue cursor-default' : 'font-normal'}`} onClick={onClick}>
        {label}
      </button>
    </>
  );
}