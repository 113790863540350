import React, {useEffect} from 'react';
import {useAppDispatch} from '../../hooks/hooks';
import {updatePageTitle} from '../../store/appSlice';
import WidthWrapper from '../../components/WidthWrapper';
import {Link} from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import PaddedButton from '../../components/PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import QueryHandlerBase from '../../components/QueryHandlerBase';
import FillHeightWrapper from '../../components/FillHeightWrapper';
import LoadWheel from '../../components/LoadWheel';
import BasicError from '../../components/BasicError';
import {stringifyDate} from '../../helpers/helper';
import {PageRoutingEnum} from '../../enums/Enums';
import ImageWrapper from '../../components/ImageWrapper';

export default function PublicHome() {
  const dispatch = useAppDispatch();
  const {loading, error, data} = useQuery(gql`
    query Query {
      openBatch {
        id
        batchNumber
        semester
        registrationEnd
      }
      nextOpenBatch {
        batchNumber
        semester
        registrationStart
      }
    } 
  `);

  useEffect(() => {
    dispatch(updatePageTitle('Welcome'));
    return (() => {
      dispatch(updatePageTitle(undefined));
    });
  }, [dispatch]);

  const loadScreen = (
    <FillHeightWrapper centerVertically verticalWidthUnwrap>
      <LoadWheel/>
    </FillHeightWrapper>
  );

  const errorScreen = (
    <FillHeightWrapper centerVertically verticalWidthUnwrap>
      <WidthWrapper>
        <BasicError error={error}/>
      </WidthWrapper>
    </FillHeightWrapper>
  );

  return (
    <QueryHandlerBase loading={loading} error={error} loadScreen={loadScreen} errorScreen={errorScreen}>
      <FillHeightWrapper verticalWidthUnwrap centerVertically>
        <>
          <div className={'h-full w-full absolute top-0 left-0 bg-black'}>
            <ImageWrapper alt={'Think. Make. Start.'} src={'/images/splash.jpg'} className={'w-full h-full opacity-30 bg-white'} icon={<></>}></ImageWrapper>
          </div>
          <WidthWrapper className={'text-center z-10 text-white'}>
            <div className={'flex flex-col gap-4 items-center justify-start'}>
              {
                data && data.openBatch &&
                <>
                  <div>
                    <h1>Be part of Batch {data.openBatch.batchNumber}!</h1>
                    <p>Registrations are open until {stringifyDate(data.openBatch.registrationEnd, {second: '2-digit'})}</p>
                  </div>
                  <Link to={PageRoutingEnum.PUBLIC_APPLY}>
                    <PaddedButton label={'apply Now'} icon={<ArrowForward/>} trailingIcon sxColor={'#fff'} sxColor80Pct={'rgba(255, 255, 255, 0.8)'}/>
                  </Link>
                </>
              }
              {
                data && !data.openBatch && data.nextOpenBatch &&
                <>
                  <div>
                    <h1>Stay updated on Batch {data.nextOpenBatch.batchNumber}!</h1>
                    <p>Registrations open on {stringifyDate(data.nextOpenBatch.registrationStart)}</p>
                  </div>
                  <Link to={PageRoutingEnum.PUBLIC_SUBSCRIBE}>
                    <PaddedButton label={'Keep me posted'} icon={<ArrowForward/>} sxColor={'#fff'} sxColor80Pct={'rgba(255, 255, 255, 0.8)'} trailingIcon/>
                  </Link>
                </>
              }
              {
                data && !data.openBatch && !data.nextOpenBatch &&
                <>
                  {/*<div>*/}
                  <h1>Stay up to date on upcoming TMS events</h1>
                  {/*<p>Please visit <a href={'https://tms.tum.de'}>tms.tum.de</a> for more information</p>*/}
                  {/*</div>*/}
                  <Link to={PageRoutingEnum.PUBLIC_SUBSCRIBE}>
                    <PaddedButton label={'Keep me posted'} icon={<ArrowForward/>} sxColor={'#fff'} sxColor80Pct={'rgba(255, 255, 255, 0.8)'} trailingIcon/>
                  </Link>
                </>
              }
            </div>
          </WidthWrapper>
        </>
      </FillHeightWrapper>
    </QueryHandlerBase>
  );
}