import React, {useState} from 'react';
import FieldWrapper from '../../components/applicationForm/FieldWrapper';
import {MenuItem, TextField} from '@mui/material';
import {SubscribeForm} from '../../models/SubscribeForm';
import {facultyList} from '../../enums/FacultyList';
import TmsFormDropdown from '../../components/TmsFormDropdown';
import TmsFormCheckbox from '../../components/TmsFormCheckbox';
import PaddedButton from '../../components/PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import {gql, useMutation} from '@apollo/client';
import {useAppDispatch} from '../../hooks/hooks';
import {updateBlockScreen, updateSnackbar} from '../../store/appSlice';
import {PageRoutingEnum} from '../../enums/Enums';
import {Link} from 'react-router-dom';

type State = {
  form?: Partial<SubscribeForm>,
  validationErrors?: {[key in keyof SubscribeForm]?: string},
  hideForm?: boolean,
}

export default function PublicSubscribe() {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({});
  const {form, validationErrors, hideForm} = state;

  const [postMutation] = useMutation(gql`
    mutation Mutation ($data: newsletterSubscriptionInput!) {
      newsletterSubscribe (data: $data)
    }
  `, {variables: {
    data: {
      name: form?.name,
      email: form?.email,
      faculty: form?.faculty,
      expectedGraduation: form?.expectedGraduation,
    }
    }});

  const updateForm = (obj: Partial<SubscribeForm>) => {
    const keys = Object.keys(obj) as (keyof SubscribeForm)[];
    const veCopy = {...validationErrors};
    for (const k of keys) delete veCopy[k];
    setState({...state, form: {...form, ...obj}, validationErrors: veCopy});
  };

  const submitForm = () => {
    dispatch(updateBlockScreen(true));
    postMutation()
      .then(() => setState({...state, hideForm: true}))
      .catch((e) => {
        dispatch(updateSnackbar({message: e.graphQLErrors[0]?.message || e.message, isError: true}));
        setState({...state, validationErrors: e.graphQLErrors[0]?.extensions?.validationErrors});
      })
      .finally(() => dispatch(updateBlockScreen(false)));
  }

  return (
    <div className={'flex flex-col gap-4'}>
      {
        !hideForm &&
        <>
          <FieldWrapper>
            <h1>Subscribe to TMS updates</h1>
          </FieldWrapper>
          <FieldWrapper>
            <p>We'll keep the spam to the minimum and only update you about when the next Think. Make. Start. events are happening.</p>
          </FieldWrapper>
          <FieldWrapper>
            <TextField label={'Name'} value={form?.name || ''} onChange={(e) => updateForm({name: e.target.value})}
                       variant={'filled'} error={!!validationErrors?.name} helperText={validationErrors?.name} fullWidth autoFocus/>
          </FieldWrapper>
          <FieldWrapper>
            <TextField label={'Email address'} type={'email'} value={form?.email || ''} error={!!validationErrors?.email}
                       helperText={validationErrors?.email}
                       onChange={(e) => updateForm({email: e.target.value.replaceAll(' ', '').toLowerCase()})} variant={'filled'} fullWidth/>
          </FieldWrapper>
          <FieldWrapper description={'If you are a visiting/exchange student, please select: Other (external)'}>
            <TmsFormDropdown value={form?.faculty || ''} label={'Faculty'} error={!!validationErrors?.faculty}
                             helperText={validationErrors?.faculty}
                             onChange={(e) => updateForm({faculty: e.target.value})} fullWidth>
              {
                [...facultyList].map((faculty) => <MenuItem key={faculty} value={faculty}>{faculty}</MenuItem>)
              }
            </TmsFormDropdown>
          </FieldWrapper>
          <FieldWrapper description={'Select the year you are expected to graduate.'}>
            <TmsFormDropdown label={'Expected graduation'} value={form?.expectedGraduation?.toString() || ''}
                             error={!!validationErrors?.expectedGraduation} helperText={validationErrors?.expectedGraduation}
                             onChange={(e) => updateForm({expectedGraduation: e.target.value})} fullWidth>
              {
                new Array(6).fill(null).map((e, ix) => {
                  const year = ix === 5 ? 'n/a' : (new Date().getFullYear() + ix).toString();
                  return (<MenuItem key={ix} value={year}>{year === 'n/a' ? 'Not applicable' : year}</MenuItem>);
                })
              }
            </TmsFormDropdown>
          </FieldWrapper>
          <FieldWrapper>
            <TmsFormCheckbox checked={form?.consent}
                             label={'I hereby consent to data processing carried out by the Technical University of Munich.'}
                             onClick={() => updateForm({consent: !form?.consent})}/>
          </FieldWrapper>
          <FieldWrapper className={'mt-2'}>
            <PaddedButton label={'Subscribe'} className={'w-full'} icon={<ArrowForward/>} trailingIcon disabled={!form?.consent}
                          onClick={() => submitForm()}/>
          </FieldWrapper>
        </>
      }
      {
        hideForm &&
        <>
          <h1>Thank you for subscribing!</h1>
          <div>
            <p>An email confirming your subscription was sent to <strong>{form?.email}</strong>. You can unsubscribe at anytime <Link to={`/${PageRoutingEnum.PUBLIC_UNSUBSCRIBE}`}>here</Link>, or using the link included in our newsletter emails.</p>
          </div>
        </>
      }
    </div>
  );
}