import React, {useEffect} from 'react';
import OutletBasic from '../components/OutletBasic';
import FillHeightWrapper from '../components/FillHeightWrapper';
import WidthWrapper from '../components/WidthWrapper';
import {useAppDispatch} from '../hooks/hooks';
import {updatePageTitle} from '../store/appSlice';
import PaddedButton from '../components/PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import {Link} from 'react-router-dom';

export default function PageNotFound() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updatePageTitle('Page Not Found'));
    return (() => {
      dispatch(updatePageTitle(undefined));
    });
  }, [dispatch]);

  return (
    <OutletBasic outlet={
      <FillHeightWrapper centerVertically>
        <WidthWrapper className={'-mt-20'}>
          <div className={'flex flex-col gap-4 items-center justify-start'}>
            <div className={'text-center'}>
              <h1>The requested page could not be found</h1>
            </div>
            <Link to={'/'}>
              <PaddedButton label={'Go to homepage'} icon={<ArrowForward/>} trailingIcon/>
            </Link>
          </div>
        </WidthWrapper>
      </FillHeightWrapper>
    }/>
  );
}