import React, {useState} from 'react';
import {Container, DisplayApplication} from './CoachVoteTypes'
import CoachVoteContainer from "./CoachVoteContainer";
import {
    DndContext,
    DragOverlay,
    DragStartEvent,
    DragEndEvent,
    DragOverEvent,
    useSensors,
    PointerSensor,
    useSensor,
} from "@dnd-kit/core";
import {arrayMove} from "@dnd-kit/sortable"
import {createPortal} from "react-dom";
import ApplicationCard from "./ApplicationCard";
import {gql, MutationFunctionOptions, useMutation} from "@apollo/client";
import {updateBlockScreen, updateSnackbar} from "../../store/appSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import TypeInCommentDialog from "./TypeInCommentDialog";


interface Props {
    applications: DisplayApplication[],
    onTriggerRefetch: any,
    filter: string,
    lastFetch?: Date,
    typeInChallengeDialogIsVisible?: boolean,
}

export default function CoachVoteCanvas(props: Props) {

    const {onTriggerRefetch, filter, lastFetch} = props;

    const dispatch = useAppDispatch();
    const {magicUrl} = useAppSelector((state) => state.app);

    const [activeApplicationOnDrag, setActiveApplicationOnDrag] = useState<DisplayApplication | null>(null);
    const [applications, setApplications] = useState<DisplayApplication[]>(props.applications);

    const [isCommentVisible, setIsCommentVisible] = useState(false);
    const [activeApplicationOnComment, setActiveApplicationOnComment] = useState<DisplayApplication|null>(null);

    const [voteStudent] = useMutation(gql`
        mutation Mutation ($magicUrl: String!, $voteId: String!,  $point: Int!,$lastFetch: String!) {
            coachVoteStudent (magicUrl: $magicUrl, voteId: $voteId, point: $point, lastFetch: $lastFetch)
        }
    `);

    const triggerCoachVote = (options: MutationFunctionOptions) => {
        dispatch(updateBlockScreen(true));
        voteStudent(options)
            .then(() => triggerRefetch())
            .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
            .finally(() => {
                dispatch(updateBlockScreen(false));
            });
    };

    const [commentStudent] = useMutation(gql`
        mutation Mutation ($magicUrl: String!, $voteId: String!,  $comment: String!,$lastFetch: String!) {
            commentStudent (magicUrl: $magicUrl, voteId: $voteId, comment: $comment, lastFetch: $lastFetch)
        }
    `);

    const triggerCommentonStudent = (options: MutationFunctionOptions) => {
        dispatch(updateBlockScreen(true));
        commentStudent(options)
            .then(() => triggerRefetch())
            .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
            .finally(() => {
                dispatch(updateBlockScreen(false));
            });
    };

    const triggerRefetch = () => {
        onTriggerRefetch();
    }

    const defaultContainer : Container = {
        id: 0,
        title: "Default",
    };

    const getContainers = () => {
        let container1 : Container = {
            id: 1,
            title: "Lowest"
        };
        let container3 : Container = {
            id: 3,
            title: "Medium"
        }
        let container5 : Container = {
            id: 5,
            title: "Best"
        }
        return [container5,container3, container1]
    };

    const containers = getContainers();

    const sensors = useSensors(useSensor(PointerSensor,{
        activationConstraint: {
            distance: 3  //300px
        }
        })
    );

    return (
        <div className="m-auto flex min-w-screen h-full overflow-y-auto">
            <DndContext
                sensors={sensors}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
            >
                <div className="flex gap-4 align-items">
                    <div className=" ">
                        <CoachVoteContainer
                            applications={applications.filter(application => application.containerId === defaultContainer.id)}
                            key={defaultContainer.id}
                            container={defaultContainer}
                            filter={filter}
                            writeComment={writeComment}
                        />
                    </div>
                    <div className="">
                        {containers.map((container) => (<CoachVoteContainer
                                applications={applications.filter(application => application.containerId === container.id)}
                                key={container.id}
                                container={container}
                                filter={filter}
                                writeComment={writeComment}
                            />
                        ))}
                    </div>
                </div>
                {createPortal(
                    <DragOverlay>
                        {activeApplicationOnDrag && (<ApplicationCard application={activeApplicationOnDrag} writeComment={writeComment} />)}
                    </DragOverlay>, document.body)}
            </DndContext>
            {isCommentVisible&&<TypeInCommentDialog
                                  onClose={closeComment}
                                  onSendComment={commitComment}
                                  currApplication={activeApplicationOnComment}/>}
        </div>
    );

    function onDragStart(event: DragStartEvent) {
        if(event.active.data.current?.type === "DisplayApplication"){
            setActiveApplicationOnDrag(event.active.data.current.application);
            return;
        }
    }

    function onDragEnd(event: DragEndEvent) {
        const application = activeApplicationOnDrag
        setActiveApplicationOnDrag(null);
        //const  active = event.active;
        const  over  = event.over;

        if(!over) return;

        //Todo: Check if ApplicationCart did change the container to avoid refetching while there is no real change
        triggerCoachVote({
            variables: {
                magicUrl,
                voteId: application?.voteId,
                point: application?.containerId,
                lastFetch: lastFetch?.toISOString(),
            }
        })
    }

    function onDragOver(event: DragOverEvent) {
        const { active, over } = event;
        if(!over) return;

        const activeId = active.id;
        const overId = over.id;

        if(activeId === overId) return

        const isActiveAnApplication = active.data.current?.type === "DisplayApplication";
        const isOverAnApplication = over.data.current?.type === "DisplayApplication";

        if(!isActiveAnApplication) return;


        //I'm droppng a Application over another Application
        if(isActiveAnApplication && isOverAnApplication){
            setApplications( application =>  {
                const activeIndex = application.findIndex((t) => t.id === activeId)
                const overIndex = application.findIndex((t) => t.id === overId)

                application[activeIndex].containerId = application[overIndex].containerId;

                return arrayMove(application,activeIndex, overIndex);
            });
        }

        const isOverAContainer = over.data.current?.type === "Container";

        //I'm dropping a application over a container
        if(isActiveAnApplication && isOverAContainer){
            setApplications( applications =>  {
                const activeIndex = applications.findIndex((t) => t.id === activeId)

                applications[activeIndex].containerId = overId;

                return arrayMove(applications,activeIndex, activeIndex);
            });
        }
    }

    function writeComment(application: DisplayApplication){
        setIsCommentVisible(true);
        setActiveApplicationOnComment(application);
    }

    function closeComment() {
        setIsCommentVisible(false);
    }

    function commitComment(text: string) {
        setIsCommentVisible(false);
        triggerCommentonStudent({
            variables: {
                magicUrl,
                voteId: activeApplicationOnComment?.voteId,
                comment: text,
                lastFetch: lastFetch?.toISOString(),
            }
        })

        if(activeApplicationOnComment){
            let newApp = applications.find(application => activeApplicationOnComment.id === application.id)
            if(!newApp)
                return;
            newApp.comment = text;

            // @ts-ignore
            let index = applications.findIndex((t) => t.id === newApp.id);

            let newApps = applications;
            newApps[index] = newApp;
            setApplications(newApps);
        }
        setActiveApplicationOnComment(null);
    }
};