import React, {useEffect, useState} from 'react';
import StatSummary from '../../../components/StatSummary';
import {StudentRoleLabelEnum} from '../../../enums/Enums';
import {gql, useQuery} from '@apollo/client';
import {useAppSelector} from '../../../hooks/hooks';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import FormButtonWrapper from '../../../components/applicationForm/FormButtonWrapper';
import CoachVoteCanvas from "../../../components/coachVoteComponents/CoachVoteCanvas";
import {DisplayApplication} from "../../../components/coachVoteComponents/CoachVoteTypes";


type coachOverviewContentProps = {
    batchId: number,
}

type State = {
    coach?: any,
    lastFetch?: Date,
}

export default function CoachVotingContent({batchId}: coachOverviewContentProps) {
    const {magicUrl} = useAppSelector((state) => state.app);
    const [state, setState] = useState<State>({});
    const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));
    const {coach, lastFetch} = state;

    const {loading, data, error, refetch} = useQuery(gql`
    query Query ($magicUrl: String!) {
        coachByMagicUrl(magicUrl: $magicUrl) {
            email
            firstName
            id
            lastName
            coachRoles {
                isBusinessCoach
                isProblemCoach
                isTechCoach
                isAdmin
                batch {
                    sumPe
                    sumBd
                    sumTd
                    sumApplications
                }
            }
            coachVotes {
                id
                point
                comment
                recipient {
                    id
                    primaryRole
                    secondaryRole
                    motivationText
                    applicationLink
                    applicationFilename
                    cvFilename
                    student {
                        id
                        firstName
                        lastName
                        imgUrl
                        faculty
                        email
                    }
                }
            }
        }
    }
    `, {variables: {magicUrl, batchId}, fetchPolicy: 'network-only'});

    let applications = coach?.coachVotes.length > 0 ? coach?.coachVotes.map( (vote: { recipient: any, point: number, id: string, comment: string }) => {
        let application = vote.recipient;
        application.voteId = vote.id;
        application.containerId = vote.point;
        application.comment = '0';
        application.comment = vote.comment;
        return application as DisplayApplication;
    }) : [];

    const PROBLEM_EXPERT = 'PROBLEM_EXPERT';
    const TECH_DEVELOPER = 'TECH_DEVELOPER';
    const BUSINESS_DEVELOPER = 'BUSINESS_DEVELOPER';

    const [filter, setFilter] = useState("all");

    useEffect(() => {
        updateState({coach: data?.coachByMagicUrl, lastFetch: new Date()});
    }, [data]);

    applications = applications.toSorted((a:any, b:any) => {
        const getScore = (role:string) => {
            if(role === PROBLEM_EXPERT) return 1;
            else if(role === TECH_DEVELOPER) return 2;
            else return 3;
        }
        if (a.primaryRole !== b.primaryRole) {
            return getScore(a.primaryRole) > getScore(b.primaryRole)? 1 : -1;
        } else {
            return a.student.firstName > b.student.firstName? 1 : -1;
        }
    });

    const getSummaryItems = () => {
        let list : any[] = []
        if (coach?.coachRoles[0].isProblemCoach) {
            list.push({
                key: StudentRoleLabelEnum.PROBLEM_EXPERT,
                value: coach.coachRoles[0].batch.sumPe,
                selected: (filter === PROBLEM_EXPERT),
                onClick: () => setFilter(PROBLEM_EXPERT),
                bigger: false
            });
        }
        if (coach?.coachRoles[0].isTechCoach) {
            list.push({
                key: StudentRoleLabelEnum.TECH_DEVELOPER,
                value: coach.coachRoles[0].batch.sumTd,
                selected: (filter === TECH_DEVELOPER),
                onClick: () => setFilter(TECH_DEVELOPER),
                bigger: false
            });
        }
        if (coach?.coachRoles[0].isBusinessCoach) {
            list.push({
                key: StudentRoleLabelEnum.BUSINESS_DEVELOPER,
                value: coach.coachRoles[0].batch.sumBd,
                selected: (filter === BUSINESS_DEVELOPER),
                onClick: () => setFilter(BUSINESS_DEVELOPER),
                bigger: false
            })
        }
        return list;
    }

    return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col items-start gap-4'}>
        {
          coach?.coachVotes &&
          <>
            <h2>Your applicants to vote on</h2>
            <StatSummary items={getSummaryItems()}/>
              {filter === "all" && <div>Please select the category of participants you want to vote on</div>}
            <FormButtonWrapper>
                <CoachVoteCanvas onTriggerRefetch={refetch} filter={filter} applications={applications} lastFetch={lastFetch}/>
            </FormButtonWrapper>
          </>
        }
      </div>
    </QueryHandlerBase>
    );
}