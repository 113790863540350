import {createSlice} from '@reduxjs/toolkit';
import {BatchX} from '../models/BatchX';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    appName: 'Think. Make. Start. Application System',
    magicUrl: undefined as String | null | undefined,
    blockScreen: 0 as number,
    openBatch: null as BatchX | null,
    snackbar: {
      message: undefined as string | undefined,
      autoHideDuration: 5000 as number,
      isError: false as boolean,
    },
  },
  reducers: {
    updatePageTitle: (state, action) => {
      const titleInput: string = action.payload;
      let fullTitle: string = state.appName;
      if (titleInput) {
        fullTitle = `${titleInput} - ${state.appName}`;
      }
      document.title = fullTitle;
    },
    updateMagicUrl: (state, action) => {
      state.magicUrl = action.payload;
    },
    updateBlockScreen: (state, action) => {
      if (action.payload === false) {
        state.blockScreen -= 1;
      } else {
        state.blockScreen += 1;
      }
    },
    updateSnackbar: (state, action) => {
      state.snackbar = {...state.snackbar, ...action.payload};
    },
  },
});

export const {
  updatePageTitle,
  updateMagicUrl,
  updateBlockScreen,
  updateSnackbar,
} = appSlice.actions;
export default appSlice.reducer;