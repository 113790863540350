import React, {useCallback, useEffect, useState} from 'react';
import Application from '../../models/Application';
import {uploadSingleFile} from "../../services/UploadService";
import FillHeightWrapper from "../FillHeightWrapper";

interface ApplicationFormFileUploaderProps {
  profile?: File,
  cv?: File,
  applicationFile?: File,
  application: Partial<Application>,
}

interface UploadStatus {
  profile?: boolean,
  cv?: boolean,
  applicationFile?: boolean,
}

interface State {
  uploadStatus?: UploadStatus,
}

export default function ApplicationFormFileUploader({profile, cv, applicationFile, application}: ApplicationFormFileUploaderProps) {
  const [state, setState] = useState<State>({});
  const {uploadStatus} = state;
  const updateUploadStatus = (o: Partial<UploadStatus>) => {
    setState((state) => ({...state, uploadStatus: {...state.uploadStatus, ...o}}));
  };

  const uploadAllFiles = useCallback(() => {
    uploadSingleFile('profile', profile, {id: application.student?.id || ''}, `student/${application.student?.magicUrl}`).then(() => {
      updateUploadStatus({profile: true});
    }).catch(() => {
      updateUploadStatus({profile: false});
    });
    uploadSingleFile('cv', cv, {id: application.id || ''}, `student/${application.student?.magicUrl}`).then(() => {
      updateUploadStatus({cv: true});
    }).catch(() => {
      updateUploadStatus({cv: false});
    });
    uploadSingleFile('supplement', applicationFile, {id: application.id || ''}, `student/${application.student?.magicUrl}`).then(() => {
      updateUploadStatus({applicationFile: true});
    }).catch(() => {
      updateUploadStatus({applicationFile: false});
    });
  }, [profile, cv, applicationFile, application]);

  useEffect(() => {
    uploadAllFiles();
  }, [uploadAllFiles]);

  //TODO: Add Alert when leaving site while upload
  return (
    <FillHeightWrapper centerVertically verticalWidthUnwrap>
      <div className={'flex flex-col gap-4 text-center'}>
        {
          uploadStatus && Object.values(uploadStatus).includes(undefined) &&
          <>
            <h2>Uploading files...</h2>
            <p>Please do not leave/refresh this page.</p>
          </>
        }
        {
          uploadStatus && !Object.values(uploadStatus).includes(undefined) &&
          <>
            <p>Thank you for submitting your application.</p>
            <h2 className={'m-0'}>To complete your application,</h2>
            <p>
              <b>please verify your email address</b> by clicking on the link in the email sent to {application.student?.email}.
            </p>
            <p>All the best,<br/>Your TMS team</p>
          </>
        }
      </div>
    </FillHeightWrapper>
  );
}