import React from 'react';
import FieldWrapper from '../applicationForm/FieldWrapper';
import {ApplicationFormType} from '../../models/ApplicationX';
import {applicationFormModuleProps} from '../../models/ApplicationFormX';
import FileUploader from '../applicationForm/FileUploader';
import {AttachFile, LockPerson} from '@mui/icons-material';

export default function ResumeModule({form, onFormUpdated, validationErrors}: applicationFormModuleProps) {
  const updateForm = (item: Partial<ApplicationFormType>) => {
    onFormUpdated(item);
  };

  return (
    <>
      <h2>Resume</h2>
      <FieldWrapper description={<><LockPerson/> Please select .pdf files only. Size of file should not exceed 15MB.</>}>
        <FileUploader icon={<AttachFile/>} fileLimit={1} accept={'.pdf'}
                      helperText={validationErrors?.cvFilename || ''}
                      error={!!validationErrors?.cvFilename}
                      files={form.cv ? [form.cv] : []}
                      onFileChange={(files) => updateForm({cv: files[0], cvFilename: files[0].name})}
                      onDeleteSingleFile={() => updateForm({cv: undefined, cvFilename: ''})}/>
      </FieldWrapper>
    </>
  );
}