import React from 'react';
import FieldWrapper from '../applicationForm/FieldWrapper';
import {ApplicationFormType} from '../../models/ApplicationX';
import {applicationFormModuleProps} from '../../models/ApplicationFormX';
import FileUploader from '../applicationForm/FileUploader';
import {AttachFile, OpenInNew} from '@mui/icons-material';
import FormButtonWrapper from '../applicationForm/FormButtonWrapper';
import {StudentRoleEnum} from '../../enums/Enums';
import StudentRoleButton from '../applicationForm/StudentRoleButton';
import {TextField} from '@mui/material';

export default function ApplicationModule({form, onFormUpdated, validationErrors}: applicationFormModuleProps) {
  const updateForm = (item: Partial<ApplicationFormType>) => {
    onFormUpdated(item);
  };

  return (
    <>
      <h2>Your role at Think. Make. Start.</h2>
      <FieldWrapper header={'Primary role'}
                    description={'This is the main role you are applying for. Select the role which suits you best!'}>
        <FormButtonWrapper error={!!validationErrors?.primaryRole}
                           helperText={validationErrors?.primaryRole}>
          <>
            {
              Object.values(StudentRoleEnum).slice(0, -1).map((role) =>
                <StudentRoleButton role={role} onClick={() => {
                  if (role === form.secondaryRole) {
                    updateForm({
                      primaryRole: role,
                      secondaryRole: undefined,
                    });
                  } else {
                    updateForm({primaryRole: role});
                  }
                }} selection={form.primaryRole} key={role}/>)
            }
          </>
        </FormButtonWrapper>
      </FieldWrapper>
      <FieldWrapper header={'Secondary role'}
                    description={'At Think. Make. Start. most students tend to fulfill more than two roles. Pick a secondary role which you would could see yourself functioning as!'}>

        <FormButtonWrapper error={!!validationErrors?.secondaryRole}
                           helperText={validationErrors?.secondaryRole}>
          <>
            {
              Object.values(StudentRoleEnum).slice(0, -1).map((role) =>
                <StudentRoleButton role={role} onClick={() => updateForm({secondaryRole: role})}
                                   selection={form.secondaryRole} disabled={form.primaryRole === role}
                                   key={role} summary/>)
            }
          </>
        </FormButtonWrapper>
      </FieldWrapper>
      <h2>Your experience</h2>
      <FieldWrapper header={'Why are you applying?'}
                    description={<>Talk about your strength and your expectations, as well as what you expect to
                      get out of Think. Make.
                      Start.<br/><br/>{(form.motivationText?.length || 0) === 0 ? '0' : form.motivationText?.trim().split(/\s+/).length} /
                      50 words (suggestion)</>}>
        <TextField rows={5}
                   value={form.motivationText || ''}
                   onChange={(e) => updateForm({motivationText: e.target.value})}
                   label={'Short motivation'}
                   error={!!validationErrors?.motivationText}
                   helperText={validationErrors?.motivationText || ''}
                   variant={'filled'} multiline fullWidth/>
      </FieldWrapper>
      <FieldWrapper header={'Application document'}>
        <p className={'m-0'}>This is your primary application document. Just show what projects you've done in the past, demonstrate your skills, and state why others should vote for you to become part of TMS. You can view a sample application for a tech role <a href={'/images/sample_application_document.pdf'} rel={'noreferrer'} target={'_blank'}>here <OpenInNew/></a>.</p>
      </FieldWrapper>
      <FieldWrapper description={'Please select .pdf files only. Size of file should not exceed 15MB.'}>
        <FileUploader icon={<AttachFile/>} fileLimit={1} accept={'.pdf'}
                      helperText={validationErrors?.applicationFilename ? validationErrors.applicationFilename : ''}
                      error={!!validationErrors?.applicationFilename}
                      files={form.applicationFile ? [form.applicationFile] : []}
                      onFileChange={(files) => updateForm({
                        applicationFile: files[0],
                        applicationFilename: files[0].name
                      })}
                      onDeleteSingleFile={() => updateForm({
                        applicationFile: undefined,
                        applicationFilename: ''
                      })}/>
      </FieldWrapper>
      <FieldWrapper header={'Optional link'}>
      <p className={'m-0'}>You can provide a link with more details (e.g. a video, github repo, website). But this is totally optional. But if you do, make sure it starts with "https://".</p>
      </FieldWrapper>
      <FieldWrapper>
        <TextField label={'URL (optional)'}
                   value={form.applicationLink || ''}
                   onChange={(e) => updateForm({applicationLink: e.target.value})}
                   error={!!validationErrors?.applicationLink}
                   helperText={validationErrors?.applicationLink || ''}
                   variant={'filled'} fullWidth/>
      </FieldWrapper>
    </>
  );
}