import React, {useState} from 'react';
import {TextField} from '@mui/material';
import PaddedButton from './PaddedButton';
import FillHeightWrapper from './FillHeightWrapper';
import {gql, useLazyQuery} from '@apollo/client';
import {useAppDispatch} from '../hooks/hooks';
import {updateBlockScreen, updateSnackbar} from '../store/appSlice';

type requestTokenOutletContentProps = {
  forCoach?: boolean,
}

type State = {
  lrzOrEmail?: string,
  isRequestSuccessful: boolean,
  validationErrors?: Partial<{[lrzOrEmail: string]: string | undefined}>,
  targetEmail?: string,
}

export default function LoginRecoverContent({forCoach}:  requestTokenOutletContentProps) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({isRequestSuccessful: false});
  const {lrzOrEmail, isRequestSuccessful, validationErrors, targetEmail} = state;

  const [getQuery] = useLazyQuery(gql`
    query Query ($forCoach: Boolean, $lrzOrEmail: String) {
      recoverLoginLink (lrzOrEmail: $lrzOrEmail, forCoach: $forCoach)
    }
  `);

  const submitForm = () => {
    console.log('Submit form');
    dispatch(updateBlockScreen(true));
    getQuery({variables: {forCoach: forCoach, lrzOrEmail: lrzOrEmail}})
      .then((res) => {
        if (res.data?.recoverLoginLink) {
          setState({...state, isRequestSuccessful: true, targetEmail: res.data?.recoverLoginLink});
        }
        if (res.error) {
          dispatch(updateSnackbar({message: res.error.message, isError: true}));
          setState({...state, validationErrors: res.error.graphQLErrors[0]?.extensions?.validationErrors || undefined});
        }
      })
      .catch((e) => dispatch(updateSnackbar({message: e, isError: true})))
      .finally(() => dispatch(updateBlockScreen(false)));
  };

  return (
    <FillHeightWrapper centerVertically verticalWidthUnwrap>
      <div className={`flex flex-col gap-4 max-w-[500px] ${!isRequestSuccessful ? '-mt-3' : ''} text-center`}>
        {
          !isRequestSuccessful &&
          <>
            <div>
              <h1>Recover your login link</h1>
              <p>
                Use the form below to request your personal login link to be sent to your email address.
                {
                  !forCoach &&
                  <span> If you provided an LRZ/TUM ID during application, the link will be sent to your <strong>@mytum.de</strong> email address.</span>
                }
              </p>
            </div>
            <div className={'flex gap-4 flex-wrap'}>
              <TextField value={state.lrzOrEmail || ''} label={`${!forCoach ? 'LRZ/TUM ID or email address' : 'Email address'}`}
                         variant={'filled'} className={'flex-grow'} error={!!validationErrors?.lrzOrEmail}
                         helperText={validationErrors?.lrzOrEmail} type={'email'} autoFocus
                         onKeyDown={(e) => e.key === 'Enter' && submitForm()}
                         onChange={(e) => setState({...state, lrzOrEmail: e.target.value.toLowerCase().replaceAll(' ', ''), validationErrors: {...state.validationErrors, lrzOrEmail: undefined}})}/>
              <div className={'flex-grow sm:pb-6 sm:flex-grow-0'}>
                <PaddedButton label={'Send Link'} growOnMobile onClick={() => submitForm()}/>
              </div>
            </div>
          </>
        }
        {
          isRequestSuccessful &&
          <div>
            <h1>We've received your request</h1>
            <p>If you are registered with our database, you will receive an email with your personal login link sent to <strong>{targetEmail}</strong> shortly.</p>
          </div>
        }
      </div>
    </FillHeightWrapper>
  );
}