import axios from 'axios';
import config from '../config.json';

export async function uploadSingleFile(uriEndpoint: string, file?: File, detail?: { [key: string]: string }, authToken?: string) {
  if (!file || !uriEndpoint) return undefined;

  const data = new FormData();
  data.append('file', file);
  if (detail) {
    for (const o of Object.keys(detail)) {
      data.append(o, detail[o]);
    }
  }
  let headers: { [key: string]: string } = {
    'Content-Type': 'multipart/form-data',
  }
  if (authToken && authToken !== '') {
    headers.Authorization = authToken;
  }
  return await axios.post(`${config.UPLOAD_ENDPOINT}/${uriEndpoint}`, data, {headers});
}