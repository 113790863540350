import React from 'react';
import Sidebar from './Sidebar';
import QueryHandlerBase from './QueryHandlerBase';
import {ApolloError} from '@apollo/client';
import Header from './Header';

type SidebarAndOutletProps = {
  loading?: boolean,
  error?: ApolloError,
  errorMessage?: JSX.Element,
  children?: JSX.Element,
  outlet: JSX.Element,
  header?: JSX.Element,
  loadScreen?: JSX.Element,
}

export default function OutletBasic({loading, error, errorMessage, children, outlet, header, loadScreen}: SidebarAndOutletProps) {
  return (
    <>
      {header || <Header/>}
      {
        error && <div className={'lg:hidden'}>
          <Sidebar>
            {children}
          </Sidebar>
        </div>
      }
      <QueryHandlerBase loading={loading} error={error} errorScreen={errorMessage} loadScreen={loadScreen}>
        <>
          {outlet}
          <div className={'lg:hidden'}>
            <Sidebar>
              {children}
            </Sidebar>
          </div>
        </>
      </QueryHandlerBase>
    </>
  );
}