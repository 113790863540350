import React from 'react';
import TmsFormDropdown from './TmsFormDropdown';
import {MenuItem, SelectChangeEvent} from '@mui/material';
import {BatchX} from '../models/BatchX';

type BatchSelectorProps = {
  selectedBatch?: BatchX,
  batches: BatchX[],
  onChange: (e: SelectChangeEvent) => void,
}

export default function BatchSelector({selectedBatch, batches, onChange}: BatchSelectorProps) {
  return (
    <div>
      {
        selectedBatch &&
        <TmsFormDropdown label={'Think. Make. Start.'} value={selectedBatch.id.toString() || ''}
                         onChange={(e) => onChange(e)}>
          {
            batches.map((b) =>
              <MenuItem key={b.id} value={b.id}>
                <span>Batch {b.batchNumber} ({b.semester})</span>
              </MenuItem>)
          }
        </TmsFormDropdown>
      }
    </div>
  );
}