import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {Alert, AlertTitle, MenuItem, TextField} from '@mui/material';
import {gql, useLazyQuery} from '@apollo/client';
import {updatePageTitle} from '../../store/appSlice';
import ApplicationState from '../../components/ApplicationState';
import QueryHandlerBase from '../../components/QueryHandlerBase';
import {ApplicationForStudentSelfAsFormWithResponse} from '../../models/ApplicationX';
import {
  ApplicationStateEnum,
  FormPropLabelEnum,
  StudentRoleEnum,
  StudentRoleLabelEnum
} from '../../enums/Enums';
import BatchSelector from '../../components/BatchSelector';
import FieldWrapper from '../../components/applicationForm/FieldWrapper';
import {stringifyDate} from '../../helpers/helper';
import TmsFormDropdown from '../../components/TmsFormDropdown';
import PaddedButton from '../../components/PaddedButton';
import LinkNewTab from '../../components/LinkNewTab';
import {OpenInNew} from '@mui/icons-material';
import config from '../../config.json';
import {StudentForStudentSelfWithApplicationsAsForm} from '../../models/StudentX';
import ConfirmParticipation from '../../components/ConfirmParticipation';
import ApplicationMissingFile from "../../components/applicationFormModules/ApplicationMissingFile";

type State = {
  student?: StudentForStudentSelfWithApplicationsAsForm,
  selectedApplication?: ApplicationForStudentSelfAsFormWithResponse,
  typeInChallengeDialogIsVisible?: boolean,
}

export default function StudentApplication() {
  const dispatch = useAppDispatch();
  const magicUrl = useAppSelector((state) => state.app.magicUrl);
  const [state, setState] = useState<State>({});
  const {student, selectedApplication} = state;
  const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));

  const [getQuery, {data, loading, error, refetch}] = useLazyQuery(gql`
    query Query ($magicUrl: String!) {
      studentGetStudent (magicUrl: $magicUrl) {
        id
        firstName
        lrzId
        email
        applications {
          id
          state
          primaryRole
          secondaryRole
          cvFilename
          applicationFilename
          applicationLink
          motivationText
          isFirstAccess
          missingFiles
          batch {
            id
            batchNumber
            semester
            registrationEnd
            acceptDeadline
          }
        }
      }
    }
  `, {variables: {magicUrl}, fetchPolicy: 'network-only'});

  useEffect(() => {
    getQuery().then();
  }, [magicUrl, getQuery]);

  useEffect(() => {
    updateState({
      student: data?.studentGetStudent,
      selectedApplication: data?.studentGetStudent?.applications[0],
    });
  }, [data]);

  useEffect(() => {
    dispatch(updatePageTitle('Application'));
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div>
        {
          student?.applications && student?.applications.length > 0 && selectedApplication &&
          <>
            <div className={'flex flex-col gap-4'}>
              {
                student.applications.length > 1 &&
                <BatchSelector selectedBatch={selectedApplication.batch}
                               batches={student.applications.map((a) => a.batch)}
                               onChange={(e) => updateState({
                                 selectedApplication: student.applications.find((el) =>
                                   el.batch.id === Number.parseInt(e.target.value))
                               })}/>
              }
              {
                student.applications.length === 1 && <h1>Batch {selectedApplication.batch.batchNumber} ({selectedApplication.batch.semester})</h1>
              }
              {
                selectedApplication.isFirstAccess &&
                <FieldWrapper>
                  <Alert severity={'info'}>
                    <>
                      <AlertTitle>Thank you for verifying your application.</AlertTitle>
                      {
                        !selectedApplication.missingFiles &&
                        <span>Your application is now complete.</span>
                      }
                    </>
                  </Alert>
                </FieldWrapper>
              }
              {
                selectedApplication.missingFiles &&
                <FieldWrapper>
                  <Alert severity={'warning'}>
                    <AlertTitle>Your application is missing {selectedApplication.missingFiles.length > 1 ? 'some' : 'a'} document{selectedApplication.missingFiles.length > 1 ? 's' : ''}. This might be caused by upload errors during the application submission process. Please email them to <a href={'mailto:info.tms@tum.de'}>info.tms@tum.de</a> before {stringifyDate(new Date(selectedApplication.batch.registrationEnd).setDate(new Date(selectedApplication.batch.registrationEnd).getDate() + 1), {second: '2-digit'})}.</AlertTitle>
                    <span>Missing document{selectedApplication.missingFiles.length > 1 ? 's' : ''}:</span>
                    <ul>
                      {
                        selectedApplication.missingFiles.map((e) => <li key={e}>{FormPropLabelEnum[e]}</li>)
                      }
                    </ul>
                  </Alert>
                </FieldWrapper>
              }
              {
                selectedApplication.state === ApplicationStateEnum.ACCEPT && new Date() < new Date(selectedApplication.batch.acceptDeadline) &&
                <FieldWrapper>
                  <ConfirmParticipation name={student.firstName} batch={selectedApplication.batch} application={selectedApplication} onSuccess={refetch}/>
                </FieldWrapper>
              }
              <h2>Status</h2>
              <div className={'text-xs mb-2'}>
                <ApplicationState label={selectedApplication.state}/>
              </div>
              <h2>Your TMS Roles</h2>
              <FieldWrapper>
                <div className={'flex flex-col gap-4'}>
                  <TmsFormDropdown label={'Primary role'} value={selectedApplication.primaryRole}>
                    {
                      Object.values(StudentRoleEnum).slice(0, -1).map((role) =>
                        <MenuItem key={role} value={role}>{StudentRoleLabelEnum[role]}</MenuItem>)
                    }
                  </TmsFormDropdown>
                  <TmsFormDropdown value={selectedApplication.secondaryRole} label={'Secondary role'}>
                    {
                      Object.values(StudentRoleEnum).slice(0, -1).map((role) =>
                        <MenuItem key={role} value={role}>{StudentRoleLabelEnum[role]}</MenuItem>)
                    }
                  </TmsFormDropdown>
                </div>
              </FieldWrapper>
              <h2>Why are you applying?</h2>
              <FieldWrapper>
                <TextField rows={5}
                           value={selectedApplication.motivationText}
                           label={'Short motivation'}
                           variant={'filled'} multiline fullWidth/>
              </FieldWrapper>
              <h2 className={'mt-2 mb-2'}>Resume / CV</h2>
              <p>Please provide a current resume / cv. We only use it internally, so don't focus on it too much.</p>
              <div className={'flex gap-4'}>
                {/*<PaddedButton label={'Upload new'} icon={<Upload/>} disabled/>*/}
                {
                  selectedApplication.cvFilename &&
                  <LinkNewTab href={`${config.CDN_ENDPOINT}/${selectedApplication.cvFilename}`}>
                    <PaddedButton label={'View'} icon={<OpenInNew/>} trailingIcon/>
                  </LinkNewTab>
                }
                {
                  !selectedApplication.cvFilename && <ApplicationMissingFile fileType={'cv'} student={student} application={selectedApplication} />
                }
              </div>
              <h2>Application document</h2>
              <div className={'flex gap-4'}>
                {/*<PaddedButton label={'Upload new'} icon={<Upload/>} disabled/>*/}
                {
                  selectedApplication.applicationFilename &&
                  <LinkNewTab href={`${config.CDN_ENDPOINT}/${selectedApplication.applicationFilename}`}>
                    <PaddedButton label={'View'} icon={<OpenInNew/>} trailingIcon/>
                  </LinkNewTab>
                }
                {
                  !selectedApplication.applicationFilename && <ApplicationMissingFile fileType={'supplement'} student={student} application={selectedApplication} />
                }
              </div>
              <h2>Optional link</h2>
              <FieldWrapper>
                <TextField label={'Link (optional)'} variant={'filled'}
                           value={selectedApplication.applicationLink ? selectedApplication.applicationLink : ''} fullWidth/>
              </FieldWrapper>
            </div>
          </>
        }
        {
          student?.applications && student.applications.length === 0 &&
          <h2>There aren't any applications associated with you at the moment</h2>
        }
      </div>
    </QueryHandlerBase>
  );
}