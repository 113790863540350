import React, {useEffect, useRef, useState} from 'react';
import {debounce} from '../helpers/helper';

type fillHeightWrapperProps = {
  children: JSX.Element,
  centerVertically?: boolean,
  className?: string,
  verticalWidthUnwrap?: boolean,
}

/**
 * This is a self-adjusting full-height wrapper. It will adjust itself to the fill the remaining client window's
 * vertical space. Use this on the visually-last vertical element only.
 * @param children
 * @param centerVertically
 * @constructor
 */
export default function FillHeightWrapper({children, centerVertically, className, verticalWidthUnwrap}: fillHeightWrapperProps) {
  const wrapperDOM = useRef<HTMLDivElement>(null);
  const [wrapperH, setWrapperH] = useState<number>(0);

  useEffect(() => {
    const computeHeight = () => {
      const topBound = window.innerHeight - (wrapperDOM.current?.getBoundingClientRect().top || 0);
      setWrapperH(topBound);
    }

    computeHeight();

    window.addEventListener('resize', debounce(computeHeight));
    return () => {
      window.removeEventListener('resize', debounce(computeHeight));
    };
  }, []);

  return (
    <div ref={wrapperDOM} style={{height: `${wrapperH}px`}} className={'tms-fill-height-wrapper overflow-auto ' +
      `${centerVertically ? 'flex items-center justify-center' : ''} ` +
      `${verticalWidthUnwrap ?'-my-28 md:-my-32' : ''} ` +
      `${className || ''}`} >
      {children}
    </div>
  );
}