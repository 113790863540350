import React from 'react';

type studentVoteButtonArrowsProps = {
  icon: JSX.Element,
  disabled: boolean,
  onUpvoteStudentVote: () => void,
}

export default function StudentVoteButtonArrows({icon, disabled, onUpvoteStudentVote}: studentVoteButtonArrowsProps) {
  const upvoteStudentVote = () => {
    onUpvoteStudentVote();
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    upvoteStudentVote();
  };

  return (
    <button disabled={disabled}
            className={`text-xl p-2.5 px-4 pl-6 cursor-pointer ${disabled ? 'opacity-30 cursor-default' : ''}`}
            onClick={(e) => handleClick(e)}>
      {icon}
    </button>
  );
}