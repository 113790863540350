import React, {useEffect, useRef, useState} from 'react';
import {debounce} from '../helpers/helper';

type sidebarProps = {
  children?: JSX.Element,
  className?: string,
}

export default function Sidebar({children, className}: sidebarProps) {
  const sidebarDOM = useRef<HTMLDivElement>(null);
  const [topValue, setTopValue] = useState<number>(0);

  useEffect(() => {
    const measureBounds = () => {
      const topBound = sidebarDOM.current?.getBoundingClientRect().top;
      if (topBound) {
        setTopValue(topBound);
      }
    }

    measureBounds();

    window.addEventListener('resize', debounce(measureBounds));
    return (() => window.removeEventListener('resize', debounce(measureBounds, 100)));
  }, []);

  return (
    <>
      <div className={'sticky flex-none overflow-y-auto overflow-x-hidden hidden lg:block h-[50vh] ' + className} ref={sidebarDOM}
           style={{top: `${topValue}px`}}>
        <div>
          {children}
        </div>
      </div>
    </>
  );
}