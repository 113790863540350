import React from 'react';
import LoadWheel from './LoadWheel';

type appBlockScreenProps = {
  isVisible: boolean,
}

export default function AppBlockScreen({isVisible}: appBlockScreenProps) {
  return (
    <div className={'fixed top-0 left-0 h-full w-full z-[1500] bg-white flex justify-center items-center transition-bg-opacity duration-1000 ' +
      `${isVisible ? 'bg-opacity-80' : 'bg-opacity-0 pointer-events-none cursor-wait'}`}>
      {
        isVisible && <LoadWheel/>
      }
    </div>
  );
}