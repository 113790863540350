import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './store/store';
import {BrowserRouter} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@mui/material';
import {ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache} from '@apollo/client';
import config from './config.json';
import {inferToken} from './helpers/helper';
import axios from 'axios';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const httpLink = new HttpLink({ uri: config.GRAPHQL_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: inferToken() || null,
    }
  }));

  return forward(operation);
})


axios.interceptors.request.use(
  (config) => {
    const token = inferToken();
    if (token && config.headers) {
      config.headers.Authorization = token;
    }
    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  }
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: 'none',
          borderWidth: '0',
          borderRadius: '0.375rem',
          '&.Mui-disabled': {
            background: 'none',
          },
          '&.Mui-focused': {
            background: 'none',
          },
          '&:hover': {
            background: 'none',
          },
          '& .MuiSelect-select:focus': {
            background: 'none',
          },
          '&::before': {
            border: 'none !important',
          },
          '&::after': {
            borderColor: 'inherit',
            borderWidth: '1px',
            borderRadius: '0.375rem',
            width: '100%',
            height: '100%',
            transform: 'none',
            transition: 'all ease 300ms',
          },
          '&.Mui-focused::after': {
            borderColor: '#076bb6',
          },
          '&.Mui-error::after': {
            borderColor: '#d32f2f',
            borderWidth: '2px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            borderColor: 'rgb(229, 231, 235)',
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#3888c4',
      main: '#076bb6',
      dark: '#044a7f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#929292',
      main: '#777777',
      dark: '#535353',
      contrastText: '#000',
    },
  },
});

root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <App/>
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    </ApolloProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass AdminOutlet.tsx function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
