import React, {useEffect, useState} from 'react';
import {updatePageTitle} from '../../../store/appSlice';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {gql, useQuery} from '@apollo/client';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import {BatchForCoach} from '../../../models/BatchX';
import CoachBatchesStudentVoteAllocations from './CoachBatchesStudentVoteAllocations';
import CoachBatchesCoachVoteAllocations from './CoachBatchesCoachVoteAllocations';
import BatchSelector from '../../../components/BatchSelector';
import {CoachForCoachSelf} from '../../../models/CoachX';
import CoachBatchesDetail from './CoachBatchesDetail';

type State = {
  coach?: CoachForCoachSelf,
  selectedBatch?: BatchForCoach,
  batches?: {batch: BatchForCoach}[],
}

export default function CoachBatchesHome() {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({});
  const {selectedBatch, batches, coach} = state;

  const {loading, data, error} = useQuery(gql`
    query Query ($magicUrl: String!) {
      coachByMagicUrl (magicUrl:$magicUrl) {
        id
        email
        coachRoles (isAdmin: true) {
          id
          batch {
            id
            batchNumber
            semester
          }
        }
      }
    }
  `, {variables: {magicUrl}});

  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };

  useEffect(() => {
    data && updateState({
      coach: data?.coachByMagicUrl,
      selectedBatch: data?.coachByMagicUrl?.coachRoles[0]?.batch,
      batches: data?.coachByMagicUrl?.coachRoles || [],
    });
  }, [data]);

  useEffect(() => {
    dispatch(updatePageTitle('Batch Administration'));
  }, [dispatch]);

  return (
    <div className={'flex flex-col gap-4'}>
      <QueryHandlerBase loading={loading} error={error}>
        <>
          {
            coach && batches && batches.length > 0 && selectedBatch &&
            <>
              {
                batches.length > 1 &&
                <BatchSelector selectedBatch={selectedBatch} batches={batches.map((b) => b.batch)}
                               onChange={(e) => {setState({...state, selectedBatch: batches.find((el) => el.batch.id === Number.parseInt(e.target.value))?.batch || undefined})}}/>
              }
              {
                batches.length === 1 && <h1>Batch {selectedBatch.batchNumber} ({selectedBatch.semester})</h1>
              }
              <CoachBatchesDetail batchId={selectedBatch.id}/>
              <CoachBatchesStudentVoteAllocations batch={selectedBatch} coachEmail={coach.email}/>
              <CoachBatchesCoachVoteAllocations batch={selectedBatch} coachEmail={coach.email}/>
            </>
          }
        </>
      </QueryHandlerBase>
    </div>
  );
}