import React, {useState} from 'react';
import ImageWrapper from '../ImageWrapper';
import {AccountCircle, ArrowDownward, ArrowUpward, OpenInNew} from '@mui/icons-material';
import StudentVoteX from '../../models/StudentVoteX';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {gql, MutationFunctionOptions, useMutation} from '@apollo/client';
import StudentVoteButtonArrows from './StudentVoteButtonArrows';
import config from '../../config.json';
import {updateBlockScreen, updateSnackbar} from '../../store/appSlice';
import LinkNewTab from '../LinkNewTab';
import {StudentRoleEnum, StudentRoleLabelEnum} from '../../enums/Enums';


type studentVoteButtonProps = {
  vote: StudentVoteX,
  showCv?: boolean,
  arraySize: number,
  onTriggerRefetch: () => void,
  lastFetch: Date,
  profilePictureFile?: File,
  suppFile?: File,
  isVotesLocked: boolean,
}

export default function StudentVoteButton({vote, arraySize, lastFetch, onTriggerRefetch, profilePictureFile, suppFile, isVotesLocked, showCv}: studentVoteButtonProps) {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [upvoteStudentVote] = useMutation(gql`
    mutation Mutation ($magicUrl: String!, $id: String!, $upvote: Boolean!, $point: Int!,$lastFetch: String!) {
      studentUpvoteStudentVote (magicUrl: $magicUrl, id: $id, upvote: $upvote, point: $point, lastFetch: $lastFetch)
    }
  `);

  const triggerUpvoteStudentVote = (options: MutationFunctionOptions) => {
    dispatch(updateBlockScreen(true));
    upvoteStudentVote(options)
      .then(() => triggerRefetch())
      .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
      .finally(() => {
        dispatch(updateBlockScreen(false));
      });
  };

  const triggerRefetch = () => {
    onTriggerRefetch();
  };

  return (
    <div key={vote.id}
         className={'border rounded-md flex font-normal overflow-auto'}>
      <div className={'w-24 flex-none p-2'}>
        <ImageWrapper className={'rounded-md w-20 aspect-[1/1] bg-gray-50 border text-3xl'}
                      icon={<AccountCircle/>}
                      src={!profilePictureFile ? (vote.recipient.student.imgUrl ?`${config.CDN_ENDPOINT}/${vote.recipient.student.imgUrl}`: '') : URL.createObjectURL(profilePictureFile)}
                      alt={`Profile picture for ${vote.recipient.student.firstName} ${vote.recipient.student.lastName}`}/>
        <p className={'mt-2 mb-0 text-sm text-center opacity-80'}>#{Math.abs(arraySize + 1 - (vote.point || 0))}</p>
      </div>
      <div className={'flex flex-grow'}>
        <div className={'flex-grow p-1 pr-2 pb-0 pt-4'}>
          <div className={'flex gap-2 text-[0.65rem] flex-wrap gap-y-1 mb-2'}>
            <p className={'m-0 uppercase font-bold opacity-80 p-1.5 py-0.5 rounded-md ' +
              `${vote.recipient.primaryRole === StudentRoleEnum.UNDEFINED ? 'bg-gray-200' : ''}` +
              `${vote.recipient.primaryRole === StudentRoleEnum.PROBLEM_EXPERT ? 'bg-tms-yellow' : ''}` +
              `${vote.recipient.primaryRole === StudentRoleEnum.TECH_DEVELOPER ? 'bg-tms-blue text-white' : ''}` +
              `${vote.recipient.primaryRole === StudentRoleEnum.BUSINESS_DEVELOPER ? 'bg-tms-red text-white' : ''}`
            }>
              {StudentRoleLabelEnum[vote.recipient.primaryRole]}</p>
            <p className={'m-0 text-[0.65rem] uppercase font-bold opacity-80 p-1.5 py-0.5 rounded-md ' +
              `${vote.recipient.secondaryRole === StudentRoleEnum.UNDEFINED ? 'bg-gray-200' : ''}` +
              `${vote.recipient.secondaryRole === StudentRoleEnum.PROBLEM_EXPERT ? 'bg-tms-yellow' : ''}` +
              `${vote.recipient.secondaryRole === StudentRoleEnum.TECH_DEVELOPER ? 'bg-tms-blue text-white' : ''}` +
              `${vote.recipient.secondaryRole === StudentRoleEnum.BUSINESS_DEVELOPER ? 'bg-tms-red text-white' : ''}`
            }>
              {StudentRoleLabelEnum[vote.recipient.secondaryRole]}</p>
            <p className={'m-0 uppercase font-bold opacity-80 p-1.5 py-0.5 rounded-md bg-gray-200'}>
              {vote.recipient.student.faculty}</p>
          </div>
          <h4 className={'my-1'}>
            {vote.recipient.student?.firstName} {vote.recipient.student.lastName}</h4>
          {
            (vote.recipient.applicationFilename || vote.recipient.applicationLink) &&
            <div className={'flex gap-3 text-xs mb-3 opacity-80 flex-wrap gap-y-1'}>
              {
                vote.recipient.applicationFilename &&
                <LinkNewTab href={!suppFile ? `${config.CDN_ENDPOINT}/${vote.recipient.applicationFilename}` : URL.createObjectURL(suppFile)}>
                  <span className={'uppercase text-teal-900'}>Application <OpenInNew/></span>
                </LinkNewTab>
              }
              {
                vote.recipient.applicationLink &&
                <LinkNewTab href={vote.recipient.applicationLink}>
                  <span className={'text-ellipsis text-teal-900'}>{vote.recipient.applicationLink.replace(/(^\w+:|^)\/\//g, '').replace(/\/.+$/g, '').replace('www.', '') || 'Link'} <OpenInNew/></span>
                </LinkNewTab>
              }
              {
                vote.recipient.cvFilename && showCv &&
                <LinkNewTab href={`${config.CDN_ENDPOINT}/${vote.recipient.cvFilename}`}>
                  <span className={'uppercase text-teal-900'}>CV <OpenInNew/></span>
                </LinkNewTab>
              }
            </div>
          }
          {
            !isExpanded && <p className={'text-sm break-words'}>
            <span>
              {(vote.recipient.motivationText.length || 0) > 160 ? vote.recipient.motivationText.slice(0, 160) : vote.recipient.motivationText}
            </span>
              {
                (vote.recipient.motivationText.length || 0) > 160 &&
                <>
                  <span>... </span>
                  <span className={'opacity-70 cursor-pointer'}
                        onClick={() => setIsExpanded(!isExpanded)}>Read more</span>
                </>
              }
            </p>
          }
          {
            isExpanded && <div className={'text-sm'}>
              <p>{vote.recipient?.motivationText}</p>
              <p className={'opacity-70 cursor-pointer'} onClick={() => setIsExpanded(!isExpanded)}>Read less</p>
            </div>
          }
        </div>
        {
          !isVotesLocked && <div className={'flex flex-col cursor-default opacity-80'}
                                  onClick={(e) => e.stopPropagation()}>
            <StudentVoteButtonArrows icon={<ArrowUpward/>} disabled={(vote.point || 0) === arraySize}
                                     onUpvoteStudentVote={() => triggerUpvoteStudentVote({
                                       variables: {
                                         magicUrl,
                                         id: vote.id,
                                         upvote: true,
                                         point: vote.point || 0,
                                         lastFetch: lastFetch.toISOString(),
                                       }
                                     })}/>
            <div className={'flex-grow'}/>
            <StudentVoteButtonArrows icon={<ArrowDownward/>} disabled={(vote.point || 0) === 1}
                                     onUpvoteStudentVote={() => triggerUpvoteStudentVote({
                                       variables: {
                                         magicUrl,
                                         id: vote.id,
                                         upvote: false,
                                         point: vote.point || 0,
                                         lastFetch: lastFetch.toISOString(),
                                       }
                                     })}/>
          </div>
        }
      </div>
    </div>
  );
}