import React, {useEffect, useState} from 'react';
import StatSummary from '../../../components/StatSummary';
import {ApplicationStateEnum, StudentRoleLabelEnum} from '../../../enums/Enums';
import BroadcastButton from '../../../components/broadcastModule/BroadcastButton';
import {CoachForCoachSelf} from '../../../models/CoachX';
import {gql, useQuery} from '@apollo/client';
import {useAppSelector} from '../../../hooks/hooks';
import QueryHandlerBase from '../../../components/QueryHandlerBase';

type coachOverviewContentProps = {
  batchId: number,
}

type State = {
  coach?: CoachForCoachSelf,
}

export default function CoachApplicationsContent({batchId}: coachOverviewContentProps) {
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({});
  const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));
  const {coach} = state;

  const {loading, data, error} = useQuery(gql`
    query Query ($magicUrl: String!, $batchId: Int!) {
      coachByMagicUrl (magicUrl:$magicUrl) {
        id
        email
        coachRoles (batchId: $batchId) {
          id
          isAdmin
          batch {
            id
            batchNumber
            semester
            sumApplications
            sumPe
            sumTd
            sumBd
            sumPeAccept,
            sumPeRegistered,
            sumPeIncomplete,
            sumPeConfirmed,
            sumPeHold,
            sumPeReject,
            sumPeCancelled,
            sumTdAccept,
            sumTdRegistered,
            sumTdIncomplete,
            sumTdConfirmed,
            sumTdHold,
            sumTdReject,
            sumTdCancelled,
            sumBdAccept,
            sumBdRegistered,
            sumBdIncomplete,
            sumBdConfirmed,
            sumBdHold,
            sumBdReject,
            sumBdCancelled,
            sumAccept,
            sumRegistered,
            sumIncomplete,
            sumConfirmed,
            sumHold,
            sumReject,
            sumCancelled,
          }
        }
      }
    }
  `, {variables: {magicUrl, batchId}, fetchPolicy: 'network-only'});

  useEffect(() => {
    updateState({coach: data?.coachByMagicUrl});
  }, [data]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col items-start gap-4'}>
        {
          coach?.coachRoles &&
          <>
            <h2>All applicants</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumApplications, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPe},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTd},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBd},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Registered</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumRegistered, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeRegistered},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdRegistered},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdRegistered},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.REGISTERED]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Incomplete</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumIncomplete, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeIncomplete},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdIncomplete},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdIncomplete},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.INCOMPLETE]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Accepted</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumAccept, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeAccept},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdAccept},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdAccept},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.ACCEPT]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Confirmed</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumConfirmed, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeConfirmed},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdConfirmed},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdConfirmed},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.CONFIRMED]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Hold</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumHold, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeHold},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdHold},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdHold},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.HOLD]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Reject</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumReject, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeReject},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdReject},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdReject},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.REJECT]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
            <h2>Cancelled</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumCancelled, bigger: true},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPeCancelled},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTdCancelled},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBdCancelled},
            ]}/>
            {
              coach.coachRoles[0].isAdmin &&
              <BroadcastButton stateFilter={{[ApplicationStateEnum.CANCELLED]: true}} batch={coach.coachRoles[0].batch} coachEmail={coach.email}/>
            }
          </>
        }
      </div>
    </QueryHandlerBase>
  );
}