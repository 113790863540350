import React from 'react';
import FieldWrapper from '../applicationForm/FieldWrapper';
import ImageWrapper from '../ImageWrapper';
import {AccountCircle, Image} from '@mui/icons-material';
import {ApplicationFormType} from '../../models/ApplicationX';
import FileUploader from '../applicationForm/FileUploader';
import {applicationFormModuleProps} from '../../models/ApplicationFormX';

export default function ProfilePictureModule({form, onFormUpdated, validationErrors}: applicationFormModuleProps) {
  const updateForm = (item: Partial<ApplicationFormType>) => {
    onFormUpdated(item);
  };

  return (
    <>
      <h2>Profile Picture</h2>
      <FieldWrapper description={'Please select .jpg, .jpeg or .png files only. Size of file should not exceed 15MB.'}>
        <div className={'flex flex-col gap-4 w-48'}>
          <div className={'w-48'}>
            <ImageWrapper src={form.img ? URL.createObjectURL(form.img) : 'error'}
                          alt={`Profile picture for ${form.firstName} ${form.lastName}`}
                          className={'w-full h-full bg-gray-50 aspect-[1/1] rounded-lg text-5xl border'}
                          icon={<AccountCircle/>}/>
          </div>
          <FileUploader icon={<Image/>} fileLimit={1} accept={'.jpg,.jpeg,.png'}
                        buttonPrompt={'Select image'}
                        helperText={validationErrors?.imgUrl ? validationErrors.imgUrl : ''}
                        error={!!validationErrors?.imgUrl}
                        files={form.img ? [form.img] : []}
                        allowPreview={false}
                        onFileChange={(files) => updateForm({img: files[0], imgUrl: files[0].name})}
                        onDeleteSingleFile={() => updateForm({img: undefined, imgUrl: ''})}/>
        </div>
      </FieldWrapper>
    </>
  );
}