import React, {RefObject, useRef} from 'react';
import PaddedButton from '../PaddedButton';
import {Close, OpenInNew} from '@mui/icons-material';

type FileUploadProps = {
  fileLimit?: number,
  buttonPrompt?: string,
  icon?: JSX.Element,
  accept: string,
  onFileChange: (files: File[]) => void,
  helperText?: string,
  error?: boolean,
  onDeleteSingleFile?: (filename: string) => void,
  files: File[],
  allowPreview?: boolean;
}

export default function FileUploader({
                                       fileLimit,
                                       buttonPrompt,
                                       icon,
                                       accept,
                                       onFileChange,
                                       helperText,
                                       error,
                                       onDeleteSingleFile,
                                       files,
                                       allowPreview
                                     }: FileUploadProps) {
  const inputDOM: RefObject<HTMLInputElement> = useRef(null);

  return (
    // TODO: Re-use border element from FormButtonWrapper.tsx
    <div>
      <input type={'file'} name={'file'} className={'hidden'} accept={accept} ref={inputDOM} value={''}
             onChange={(e) => e.target.files && onFileChange(Array.from(e.target.files))}/>
      <div className={'flex flex-col gap-2 p-2 rounded-md relative before:rounded-md before:absolute ' +
        'before:w-full before:h-full before:top-0 before:left-0 before:transition-border before:duration-300 ' +
        `${error ? 'before:border-mui-error-main before:border-2' : 'before:border'}`}>
        {
          files.map((file) => <div key={file.name} className={'flex gap-4 items-center'}>
            <span className={'text-ellipsis overflow-hidden flex-grow ml-1 whitespace-nowrap'}>{file.name}</span>
            <div className={'flex gap-2 flex-none'}>
              {
                allowPreview !== false &&
                <a href={URL.createObjectURL(file)} target={'_blank'} rel={'noreferrer'}>
                  <PaddedButton label={'View'} trailingIcon icon={<OpenInNew/>}/>
                </a>
              }
              {
                onDeleteSingleFile &&
                <PaddedButton label={''} icon={<Close/>} color={'secondary'}
                              onClick={() => onDeleteSingleFile(file.name)}/>
              }
            </div>
          </div>)
        }
        {
          (fileLimit ? files.length < fileLimit : files.length === 0) ?
            <PaddedButton label={buttonPrompt ? buttonPrompt : 'Select a file to upload'} icon={icon}
                          onClick={() => inputDOM.current?.click()}/> : null
        }
      </div>
      {
        helperText ? <p className={`text-[0.75rem] mt-1.5 px-4 ` +
          `${error ? 'text-mui-error-main' : 'text-neutral-500'}`}>{helperText}</p> : null
      }
    </div>
  );
}