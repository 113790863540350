import React from 'react';

type FieldWrapperProps = {
  children?: JSX.Element,
  description?: string | JSX.Element,
  className?: string,
  header?: string,
}

export default function FieldWrapper({children, description, className, header}: FieldWrapperProps) {
  return (
    <>
      {
        header ? <h3 className={'mb-0'}>{header}</h3> : null
      }
      <div className={'grid grid-rows-1 grid-cols-1 sm:grid-cols-5 gap-2 sm:gap-4'}>
        {
          description &&
          <span className={'sm:col-span-2 text-sm opacity-50 sm:order-last sm:mt-1.5'}>{description}</span>
        }
        {
          children &&
          <span className={`sm:col-span-3 ${className ? className : ''}`}>{children}</span>
        }
      </div>
    </>
  );
}