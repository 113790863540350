import React, {useEffect, useState} from 'react';
import {BrokenImage} from "@mui/icons-material";

type ImageProps = {
  src?: string,
  alt: string,
  className?: string,
  icon?: JSX.Element,
}

export default function ImageWrapper({src, alt, className, icon}: ImageProps) {
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);

  useEffect(() => {
    setFailedToLoad(false);
  }, [src]);

  return (
    <>
      {
        !failedToLoad ?
          <img src={src} alt={alt} className={'object-cover ' + className} onError={() => setFailedToLoad(true)}/> :
          <div title={alt} className={'flex items-center justify-center ' + className}>
            <div className={'opacity-50 -mt-1'}>
              {
                icon ? icon : <BrokenImage/>
              }
            </div>
          </div>
      }
    </>
  );
}