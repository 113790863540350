import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {MenuItem, TextField} from '@mui/material';
import ImageWrapper from '../../components/ImageWrapper';
import {AccountCircle} from '@mui/icons-material';
import {updatePageTitle} from '../../store/appSlice';
import {gql, useLazyQuery} from '@apollo/client';
import config from '../../config.json';
import QueryHandlerBase from '../../components/QueryHandlerBase';
import {StudentForStudentSelfWithApplications} from '../../models/StudentX';
import {facultyList} from '../../enums/FacultyList';
import TmsFormDropdown from '../../components/TmsFormDropdown';
import FieldWrapper from '../../components/applicationForm/FieldWrapper';
import ApplicationMissingFile from "../../components/applicationFormModules/ApplicationMissingFile";

export default function StudentAccount() {
  const dispatch = useAppDispatch();
  const magicUrl = useAppSelector((state) => state.app.magicUrl);
  const [form, setForm] = useState<StudentForStudentSelfWithApplications>();
  let student =  {...form, 'applications':undefined}

  const [getQuery, {loading, error}] = useLazyQuery(gql`
    query Query ($magicUrl: String!) {
      studentGetStudent (magicUrl: $magicUrl) {
        id
        lrzId
        firstName
        lastName
        email
        imgUrl
        faculty
      }
    }
  `, {variables: {magicUrl: magicUrl}});

  useEffect(() => {
    getQuery().then((res) => {
      const copy = {...res.data.studentGetStudent};
      if (!form) setForm(copy);
    });
  }, [magicUrl, form, getQuery]);

  useEffect(() => {
    dispatch(updatePageTitle('Account'));
    return (() => {
      dispatch(updatePageTitle(undefined));
    });
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <>
        {
          form && <div className={'flex flex-col gap-4'}>
            <h1>Account</h1>
            <h2>Profile picture</h2>
              {form.imgUrl && <div className={'w-48'}>
                <ImageWrapper src={`${config.CDN_ENDPOINT}/${form.imgUrl}`}
                                                                    alt={`Profile picture for ${form.firstName} ${form.lastName}`}
                                                                    className={'w-full h-full bg-gray-50 aspect-[1/1] rounded-lg text-5xl border'}
                                                                    icon={<AccountCircle/>}/>
                </div>}
              {
                !form.imgUrl && <ApplicationMissingFile fileType={'profile'} student={student} application={undefined} />
              }
              {/*<Spacer d={4}/>*/}
              {/*TODO <PaddedButton label={'Upload new'} icon={<Upload/>} className={'w-full'} disabled/>*/}

            <h2>Personal detail</h2>
            <FieldWrapper>
              <TextField label={'LRZ ID'} value={form.lrzId} variant={'filled'} fullWidth/>
            </FieldWrapper>
            <FieldWrapper>
              <TextField label={'Email'} value={form.email} variant={'filled'} fullWidth/>
            </FieldWrapper>
            <FieldWrapper>
              <div className={'flex gap-2'}>
                <TextField label={'First name'} value={form.firstName} variant={'filled'} fullWidth/>
                <TextField label={'Last name'} value={form.lastName} variant={'filled'} fullWidth/>
              </div>
            </FieldWrapper>
          <FieldWrapper>
            <TmsFormDropdown value={form.faculty} label={'Faculty'} fullWidth>
              {
                [...facultyList].map((faculty) => <MenuItem key={faculty} value={faculty}>{faculty}</MenuItem>)
              }
            </TmsFormDropdown>
          </FieldWrapper>
            {/*<h2>Delete account</h2>*/}
            {/*<div>*/}
            {/*  <PaddedButton label={'Delete'} icon={<Delete/>} color={'error'} disabled/>*/}
            {/*</div>*/}
          </div>
        }
      </>
    </QueryHandlerBase>
  );
}