import React from 'react';
import {IconButton, Snackbar} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../hooks/hooks';
import {updateSnackbar} from '../store/appSlice';
import {Close} from '@mui/icons-material';

type appSnackbarProps = {
  isVisible: boolean,
}

export default function AppSnackbar({isVisible}: appSnackbarProps) {
  const dispatch = useAppDispatch();
  const {message, autoHideDuration, isError} = useAppSelector((state) => state.app.snackbar);

  const handleClose = () => {
    dispatch(updateSnackbar({message: undefined, autoHideDuration: 5000, isError: false}));
  }

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <Close fontSize="small"/>
    </IconButton>
  );

  return (
    <Snackbar message={isError ? <span><strong>Error:</strong> {message}</span> : message}
              open={isVisible} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              action={action} autoHideDuration={autoHideDuration} onClose={handleClose}
              sx={{
                margin: '0.5rem',
                '& .MuiSnackbarContent-root': {
                  background: 'rgba(0, 0, 0, 0.8)',
                },
              }}/>
  );
}