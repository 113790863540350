import React, {useEffect} from 'react';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import {updateMagicUrl, updatePageTitle} from '../../store/appSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import SidebarOptionLink from '../../components/SidebarOptionLink';
import OutletWithSidebar from '../../components/OutletWithSidebar';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import FillHeightWrapper from '../../components/FillHeightWrapper';
import LoadWheel from '../../components/LoadWheel';
import { PageRoutingEnum } from '../../enums/Enums';
import LoginRecoverContent from '../../components/LoginRecoverContent';

export default function StudentOutlet() {
  const path = useLocation().pathname.split('/');
  const {magicUrl} = useParams();
  const dispatch = useAppDispatch();
  const storeMagicUrl = useAppSelector((state) => state.app.magicUrl);

  const [getQuery, {loading, error}] = useLazyQuery(gql`
    query Query ($magicUrl: String!) {
      studentGetStudent (magicUrl: $magicUrl, login: true) {
        id
        applications {
          id
        }
      }
    }
  `);

  useEffect(() => {
    magicUrl && getQuery({variables: {magicUrl: magicUrl}}).then((res) => {
       dispatch(updateMagicUrl(res.data?.studentGetStudent ? magicUrl : null));
    });
  }, [magicUrl, dispatch, getQuery]);

  const navItems = (
    <>
      {
        storeMagicUrl &&
        <>
          <SidebarOptionLink label={'Home'} to={PageRoutingEnum.STUDENT_HOME} isActive={[...path].length === 3}/>
          <SidebarOptionLink label={'Account'} to={PageRoutingEnum.STUDENT_ACCOUNT}
                             isActive={[...path].pop() === PageRoutingEnum.STUDENT_ACCOUNT}/>
          <SidebarOptionLink label={'Application'} to={PageRoutingEnum.STUDENT_APPLICATION}
                             isActive={[...path].pop() === PageRoutingEnum.STUDENT_APPLICATION}/>
          <SidebarOptionLink label={'Peer Voting'} to={PageRoutingEnum.STUDENT_PEER_VOTING}
                             isActive={[...path].pop() === PageRoutingEnum.STUDENT_PEER_VOTING}/>
        </>
      }</>
  );

  const sidebar = (
    <Sidebar>{navItems}</Sidebar>
  );

  const header = (
    <Header to={PageRoutingEnum.PUBLIC_OUTLET} navItems={navItems}>
      <a href={`/${PageRoutingEnum.STUDENT_OUTLET}/${magicUrl}`}>
        <span>APPLICATION PORTAL</span>
      </a>
    </Header>
  );

  const loadScreen = (
    <FillHeightWrapper verticalWidthUnwrap centerVertically>
      <LoadWheel/>
    </FillHeightWrapper>
  );

  const outlet = (
    <>
      {
        storeMagicUrl && <Outlet/>
      }
      {
        ((magicUrl && storeMagicUrl === null) || !magicUrl) && <LoginRecoverContent/>
      }
    </>
  );

  useEffect(() => {
    dispatch(updatePageTitle('Application Portal'));
  }, [dispatch]);

  return (
    <OutletWithSidebar loading={loading} loadScreen={loadScreen} error={error}
                       header={header} outlet={outlet} sidebar={(storeMagicUrl || undefined) && sidebar}/>
  );
}