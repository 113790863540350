import React from 'react';
import FieldWrapper from '../applicationForm/FieldWrapper';
import {MenuItem, TextField} from '@mui/material';
import TmsFormCheckbox from '../TmsFormCheckbox';
import TmsFormDropdown from '../TmsFormDropdown';
import {facultyList} from '../../enums/FacultyList';
import {ApplicationFormType} from '../../models/ApplicationX';
import {applicationFormModuleProps} from '../../models/ApplicationFormX';
import {LockPerson} from '@mui/icons-material';

export default function PersonalInformationModule({form, onFormUpdated, validationErrors}: applicationFormModuleProps) {
  const updateForm = (item: Partial<ApplicationFormType>) => {
    onFormUpdated(item);
  };

  return (
    <>
      <h2>Personal Information</h2>
      <FieldWrapper
        description={<><LockPerson/> Your LRZ/TUM ID is necessary for course grading. It follows the following format: gx00xxx.</>}>
        {
          <>
            {
              !form.noLrzId && <TextField label={'LRZ/TUM ID'} variant={'filled'} autoFocus
                                          value={form.lrzId || ''}
                                          onChange={(e) => updateForm({lrzId: e.target.value.slice(0, 7).toLowerCase()})}
                                          error={!!validationErrors?.lrzId} disabled={form.noLrzId}
                                          helperText={validationErrors?.lrzId || ''} fullWidth/>
            }
            {
              !form.lrzId &&
              <TmsFormCheckbox checked={form.noLrzId} label={'I do not have an LRZ/TUM ID'}
                               onClick={() => updateForm({noLrzId: !form.noLrzId})}/>
            }
          </>
        }
      </FieldWrapper>
      <FieldWrapper description={<><LockPerson/> This will be your primary point of contact.</>}>
        <TextField label={'Email address'} variant={'filled'}
                   value={form.email || ''} type={'email'}
                   onChange={(e) => updateForm({email: e.target.value.toLowerCase().replaceAll(' ', '')})}
                   error={!!validationErrors?.email}
                   helperText={validationErrors?.email || ''} disabled={!form.noLrzId} fullWidth/>
      </FieldWrapper>
      <FieldWrapper>
        <div className={'flex gap-4'}>
          <TextField label={'First name'} variant={'filled'}
                     value={form.firstName || ''}
                     onChange={(e) => updateForm({firstName: e.target.value})}
                     error={!!validationErrors?.firstName}
                     helperText={validationErrors?.firstName || ''}
                     fullWidth/>
          <TextField label={'Last name'} variant={'filled'}
                     value={form.lastName || ''}
                     onChange={(e) => updateForm({lastName: e.target.value})}
                     error={!!validationErrors?.lastName}
                     helperText={validationErrors?.lastName || ''}
                     fullWidth/>
        </div>
      </FieldWrapper>
      <FieldWrapper description={'If you are a visiting/exchange student, please select: Other (external)'}>
        <TmsFormDropdown value={form.faculty || ''} label={'Faculty'} error={!!validationErrors?.faculty}
                         helperText={validationErrors?.faculty} fullWidth
                         onChange={(e) => updateForm({...form, faculty: e.target.value})}>
          {
            [...facultyList].map((faculty) => <MenuItem key={faculty} value={faculty}>{faculty}</MenuItem>)
          }
        </TmsFormDropdown>
      </FieldWrapper>
    </>
  );
}