import React from 'react';
import {Link, Outlet, useLocation} from 'react-router-dom';
import SidebarOptionLink from '../../components/SidebarOptionLink';
import OutletWithSidebar from '../../components/OutletWithSidebar';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import {PageRoutingEnum} from '../../enums/Enums';
import {Login} from '@mui/icons-material';
import {gql, useQuery} from '@apollo/client';

export default function PublicOutlet() {
  const path = useLocation().pathname.split('/');
  const {data} = useQuery(gql`
    query Query {
      openBatch {
        id
      }
    }
  `);

  const navItems = (
    <>
      {/*<SidebarOptionLink to={PageRoutingEnum.PUBLIC_HOME} label={'Home'} isActive={[...path].pop() === PageRoutingEnum.PUBLIC_HOME}/>*/}
      {
        data?.openBatch &&
        <SidebarOptionLink to={PageRoutingEnum.PUBLIC_APPLY} label={'Application Form'} isActive={[...path].pop() === PageRoutingEnum.PUBLIC_APPLY}/>
      }
      {/*
        [...path].pop() === PageRoutingEnum.PUBLIC_APPLY && <SidebarOptionHttpLink label={<><span>Frequently Asked Questions </span><OpenInNew/></>} href={PageRoutingEnum.PUBLIC_FAQ} isActive={[...path].pop() === PageRoutingEnum.PUBLIC_FAQ}/>
      */}
      {/*
        [...path].pop() !== PageRoutingEnum.PUBLIC_APPLY && <SidebarOptionLink label={'Frequently Asked Questions'} to={PageRoutingEnum.PUBLIC_FAQ} isActive={[...path].pop() === PageRoutingEnum.PUBLIC_FAQ}/>
      */}
      <SidebarOptionLink label={'Preregister'} to={PageRoutingEnum.PUBLIC_SUBSCRIBE} isActive={[...path].pop() === PageRoutingEnum.PUBLIC_SUBSCRIBE}/>
    </>
  );

  const sidebar = (<Sidebar>{navItems}</Sidebar>);

  const header = (
    <Header to={PageRoutingEnum.PUBLIC_OUTLET} navItems={navItems}>
      <div className={'flex gap-6 justify-end opacity-80 lg:opacity-100'}>
        {/*{
          [...path].pop() === PageRoutingEnum.PUBLIC_HOME &&
          <Link to={PageRoutingEnum.PUBLIC_FAQ} className={'hidden lg:block'}>
            <div className={'flex gap-2 items-center font-normal'}><Help/><span>FAQs</span></div>
          </Link>
        }*/}
        <Link to={PageRoutingEnum.STUDENT_OUTLET}>
          <div className={'flex gap-2 items-center font-normal'}><Login/><span>View Existing Application</span></div>
        </Link>
      </div>
    </Header>
  );

  const outlet = (
    <>
      <Outlet/>
    </>
  );

  return (
    <OutletWithSidebar header={header} outlet={outlet} sidebar={![PageRoutingEnum.PUBLIC_HOME.toString(), PageRoutingEnum.PUBLIC_UNSUBSCRIBE.toString()].includes([...path].pop() || '') ? sidebar : undefined}/>
  );
}