import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import PaddedButton from '../PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import {useState} from 'react';
import Spacer from '../Spacer';
import {DisplayApplication} from "./CoachVoteTypes";

type typeInCommentDialogProps = {
  onClose: () => void,
  onSendComment(text: string): void,
  currApplication: DisplayApplication|null,
}

export default function TypeInCommentDialog({onClose, onSendComment, currApplication}: typeInCommentDialogProps) {
  const [comment, setComment] = useState<string>(currApplication?.comment||'');

  const closeHandler = () => {
    setComment('')
    onClose();
  };

  const submitHandler = () => {
    closeHandler();
    onSendComment(comment);
  };

  return (
    <Dialog open={true} onClose={closeHandler}>
      <div className={'p-4 md:p-6'}>
        <div className={'flex flex-col gap-4'}>
          <h3>{'Comment on Application'}</h3>
          <div className={'flex flex-col items-center gap-2'}>
            <p>{`Please comment on the Application of Student ${currApplication?.student.firstName} ${currApplication?.student.lastName}`}</p>
          </div>
          <Spacer/>
          <TextField autoFocus label={'Comment'} fullWidth variant={'filled'}
                     value={comment} onChange={(e) => setComment( e.target.value)}/>
          <Spacer/>
          <div className={'flex gap-2 justify-end flex-wrap'}>
            <PaddedButton label={'Cancel'} color={'secondary'} onClick={closeHandler} growOnMobile/>
            <PaddedButton label={'Submit'} onClick={submitHandler} icon={<ArrowForward/>} trailingIcon growOnMobile/>
          </div>
        </div>
      </div>
    </Dialog>
  );
}