import {DisplayApplication} from "./CoachVoteTypes";
import React, {useState} from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import ImageWrapper from "../ImageWrapper";
import {AccountCircle, OpenInNew, Feedback} from "@mui/icons-material";
import config from "../../config.json";
import {StudentRoleEnum, StudentRoleLabelEnum} from "../../enums/Enums";
import LinkNewTab from "../LinkNewTab";
import {IconButton} from "@mui/material";



interface Props {
    application: DisplayApplication;
    score?: number | null;
    writeComment(Application:DisplayApplication) : void;
}

export default function ApplicationCard( props: Props) {

    const {application, score, writeComment} = props;

    const student = application.student
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const {
        setNodeRef,
        attributes,
        listeners,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: application.id,
        data: {
            type: "DisplayApplication",
            application,
        }
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    }

    if(isDragging) {
        return (<div ref={setNodeRef}
                     style={style}
                     className={'opacity-60 bg-black bg-opacity-5 p-2.5 pb-0 h-[170px] min-h-[170px] items-center flex text-left cursor-graps relative rounded-md  border-2 border-' +
                         `${application.primaryRole === StudentRoleEnum.UNDEFINED ? 'gray-200' : ''}` +
                         `${application.primaryRole === StudentRoleEnum.PROBLEM_EXPERT ? 'tms-yellow' : ''}` +
                         `${application.primaryRole === StudentRoleEnum.TECH_DEVELOPER ? 'tms-blue' : ''}` +
                         `${application.primaryRole === StudentRoleEnum.BUSINESS_DEVELOPER ? 'tms-red' : ''}`}
        />)
    }

    return (<div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={'bg-black bg-opacity-5 p-2.5 pb-0 min-h-[170px] flex flex-col rounded-md cursor-graps relative hover:ring-2 hover:ring-inset hover:ring-' +
            `${application.primaryRole === StudentRoleEnum.UNDEFINED ? 'gray-200' : ''}` +
            `${application.primaryRole === StudentRoleEnum.PROBLEM_EXPERT ? 'tms-yellow' : ''}` +
            `${application.primaryRole === StudentRoleEnum.TECH_DEVELOPER ? 'tms-blue' : ''}` +
            `${application.primaryRole === StudentRoleEnum.BUSINESS_DEVELOPER ? 'tms-red' : ''}`}
    >
        {application && <div className={'rounded-md flex font-normal overflow-auto'}>
            <div className={'w-24 flex-none p-2 content-center justify-center text-center'}>
                <ImageWrapper className={'rounded-md w-20 aspect-[1/1] bg-gray-50 border text-3xl'}
                              icon={<AccountCircle/>}
                              src={!application.profilePictureFile ? (student.imgUrl ? `${config.CDN_ENDPOINT}/${student.imgUrl}` : '') : URL.createObjectURL(application.profilePictureFile)}
                              alt={`Profile picture for ${student.firstName} ${student.lastName}`}/>
                {score && <div>{`#${score}`}</div>}
            </div>
            <div className={'flex flex-grow'}>
                <div className={'flex-grow p-1 pr-2 pb-0 pt-1'}>
                    <div className={'flex gap-2 text-[0.65rem] flex-wrap gap-y-1 mb-2'}>
                        <p className={'m-0 uppercase font-bold opacity-80 p-1.5 py-0.5 rounded-md ' +
                            `${application.primaryRole === StudentRoleEnum.UNDEFINED ? 'bg-gray-200' : ''}` +
                            `${application.primaryRole === StudentRoleEnum.PROBLEM_EXPERT ? 'bg-tms-yellow' : ''}` +
                            `${application.primaryRole === StudentRoleEnum.TECH_DEVELOPER ? 'bg-tms-blue text-white' : ''}` +
                            `${application.primaryRole === StudentRoleEnum.BUSINESS_DEVELOPER ? 'bg-tms-red text-white' : ''}`
                        }>
                            {StudentRoleLabelEnum[application.primaryRole]}</p>
                        <p className={'m-0 text-[0.65rem] uppercase font-bold opacity-80 p-1.5 py-0.5 rounded-md ' +
                            `${application.secondaryRole === StudentRoleEnum.UNDEFINED ? 'bg-gray-200' : ''}` +
                            `${application.secondaryRole === StudentRoleEnum.PROBLEM_EXPERT ? 'bg-tms-yellow' : ''}` +
                            `${application.secondaryRole === StudentRoleEnum.TECH_DEVELOPER ? 'bg-tms-blue text-white' : ''}` +
                            `${application.secondaryRole === StudentRoleEnum.BUSINESS_DEVELOPER ? 'bg-tms-red text-white' : ''}`
                        }>
                            {StudentRoleLabelEnum[application.secondaryRole]}</p>
                        <p className={'m-0 uppercase font-bold opacity-80 p-1.5 py-0.5 rounded-md bg-gray-200'}>
                            {student.faculty}</p>
                    </div>
                    <h4 className={'my-1'}>
                        {student?.firstName} {student.lastName}
                    </h4>
                    {
                        (application.applicationFilename || application.applicationLink) &&
                        <div className={'flex gap-3 text-xs mb-3 opacity-80 flex-wrap gap-y-1'}>
                            {
                                application.applicationFilename &&
                                <LinkNewTab
                                    href={!application.suppFile ? `${config.CDN_ENDPOINT}/${application.applicationFilename}` : URL.createObjectURL(application.suppFile)}>
                                    <span className={'uppercase text-teal-900'}>Application <OpenInNew/></span>
                                </LinkNewTab>
                            }
                            {
                                application.applicationLink &&
                                <LinkNewTab href={application.applicationLink}>
                                        <span
                                            className={'text-ellipsis text-teal-900'}>{application.applicationLink.replace(/(^\w+:|^)\/\//g, '').replace(/\/.+$/g, '').replace('www.', '') || 'Link'}
                                            <OpenInNew/></span>
                                </LinkNewTab>
                            }
                            {
                                application.cvFilename &&
                                <LinkNewTab href={`${config.CDN_ENDPOINT}/${application.cvFilename}`}>
                                    <span className={'uppercase text-teal-900'}>CV <OpenInNew/></span>
                                </LinkNewTab>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className={'flex-none p-0 content-center justify-center text-center'}>
                <IconButton size={'large'} color={application.comment===''?'default':'success'} title={application.comment} onClick={()=> {
                    writeComment(application)
                }}><Feedback/></IconButton>
            </div>
        </div>}
        {application && <div className={'flex flex-grow px-5'}>
            {
                !isExpanded && <p className={'text-sm break-words'}>
                    <span>
                        {(application.motivationText.length || 0) > 50 ? application.motivationText.slice(0, 50) : application.motivationText}
                    </span>
                    {
                        (application.motivationText.length || 0) > 50 &&
                        <>
                            <span>... </span>
                            <span className={'opacity-70 cursor-pointer'}
                                  onClick={() => setIsExpanded(!isExpanded)}>Read more</span>
                        </>
                    }
                </p>
            }
            {
                isExpanded && <div className={'text-sm'}>
                    <p>{application?.motivationText}</p>
                    <p className={'opacity-70 cursor-pointer'} onClick={() => setIsExpanded(!isExpanded)}>Read less</p>
                </div>

            }
        </div>}
    </div>)
}