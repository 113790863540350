import React from 'react';

type navItemProps = {
  label: string | JSX.Element,
  isActive?: boolean,
  onClick?: () => void,
}

export default function NavItem({label, isActive, onClick}: navItemProps) {
  return (
    <button className={`lg:hidden hover:opacity-80 active:opacity-80 ${isActive ? 'opacity-100 font-bold' : 'opacity-60 font-normal'}`} onClick={onClick}>
      {label}
    </button>
  );
}