import React from 'react';

type WidthWrapperProps = {
  children: JSX.Element,
  className?: string,
}

export default function WidthWrapper({children, className}: WidthWrapperProps) {
  return (
    <div className={`p-8 md:p-10 px-4 md:px-6 ${className ? className : ''}`}>
      <div className={`m-auto max-w-[1200px] w-full`}>
        {children}
      </div>
    </div>
  );
}