import React from 'react';
import SidebarOption from './SidebarOption';
import {Link} from 'react-router-dom';

type SidebarOptionLinkProps = {
  label: string | JSX.Element,
  isActive?: boolean,
  to: string,
}

export default function SidebarOptionLink({label, isActive, to}: SidebarOptionLinkProps) {
  return (
    <div className={`${isActive ? 'pointer-events-none cursor-default' : ''}`}>
      <Link to={to}>
        <SidebarOption label={label} isActive={isActive}/>
      </Link>
    </div>
  );
}