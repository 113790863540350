import React from 'react';
import {stringifyDate} from '../helpers/helper';
import PaddedButton from './PaddedButton';
import {Check} from '@mui/icons-material';
import Spacer from './Spacer';
import {BatchX} from '../models/BatchX';
import {gql, useMutation} from '@apollo/client';
import {ApplicationForStudentSelfAsForm} from '../models/ApplicationX';
import {useAppDispatch, useAppSelector} from '../hooks/hooks';
import {updateBlockScreen, updateSnackbar} from '../store/appSlice';

type confirmParticipationProps = {
  name: string,
  batch: BatchX,
  application: ApplicationForStudentSelfAsForm,
  onSuccess: () => void,
}

export default function ConfirmParticipation({name, batch, application, onSuccess}: confirmParticipationProps) {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);

  const [postMutation] = useMutation(gql`
    mutation Mutation ($token: String!, $applicationId: String!) {
      confirmParticipation (token: $token, applicationId: $applicationId)
    }
  `, {variables: {token: magicUrl, applicationId: application.id}});

  const confirmAcceptance = () => {
    dispatch(updateBlockScreen(true));
    postMutation()
      .then(() => onSuccess())
      .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
      .finally(() => dispatch(updateBlockScreen(false)));
  };

  return (
    <div className={'p-4 border border-black rounded-md flex flex-col gap-4'}>
      <h3 className={'font-medium'}>{name}, you're in! 🎉</h3>
      <p>Your application has made a convincing impression to your peers and the coaches. We are happy that you want to participate in Think. Make. Start. Batch {batch.batchNumber} ({batch.semester}) and would like to offer you a seat at the table. Please confirm your participation before {stringifyDate(batch.acceptDeadline, {second: '2-digit'})} by clicking on the button below.</p>
      <PaddedButton label={'Confirm participation'} icon={<Check/>} onClick={confirmAcceptance}/>
      <Spacer/>
      <p className={'text-xs opacity-80 m-0'}><strong>Note:</strong> Please write us at <a href={'mailto:info.tms@tum.de'}>info.tms@tum.de</a> as soon as possible if you can no longer participate in the course, so we could allocate your place to another applicant.</p>
    </div>
  );
}