import React, {useEffect, useState} from 'react';
import PaddedButton from '../../../components/PaddedButton';
import {gql, useMutation, useQuery} from '@apollo/client';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import {updateBlockScreen, updateSnackbar} from '../../../store/appSlice';
import {useAppDispatch} from '../../../hooks/hooks';
import {Delete, PlayArrow} from '@mui/icons-material';
import {BatchForCoach} from '../../../models/BatchX';
import TypeInChallengeDialog from '../../../components/TypeInChallengeDialog';

type coachBatchesVoteAllocationsProps = {
  batch: BatchForCoach,
  coachEmail: string,
}

type State = {
  isVotesAllocated?: boolean,
  typeInChallengeDialogIsVisible?: boolean,
}

export default function CoachBatchesStudentVoteAllocations({batch, coachEmail}: coachBatchesVoteAllocationsProps) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({});
  const {isVotesAllocated, typeInChallengeDialogIsVisible} = state;

  const {loading, data, error, refetch} = useQuery(gql`
    query Query ($id: Int!) {
      isStudentVotesAllocated (id: $id)
    }
  `, {variables: {id: batch.id}});

  const [postAdminAllocateVotes] = useMutation(gql`
    mutation Mutation ($id: Int!) {
      allocateVotes (id: $id)
    }
  `);

  const [postAdminDeleteVoteAllocations] = useMutation(gql`
    mutation Mutation ($id: Int!) {
      resetVotes (id: $id)
    }
  `);

  const submitForm = () => {
    dispatch(updateBlockScreen(true));
    if (isVotesAllocated) {
      postAdminDeleteVoteAllocations({variables: {id: batch.id}})
        .then(() => dispatch(updateSnackbar({message: 'Votes have been deleted'})))
        .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
        .finally(() => {
          refetch({id: batch.id});
          dispatch(updateBlockScreen(false));
        });
    } else {
      postAdminAllocateVotes({variables: {id: batch.id}})
        .then((res) => dispatch(updateSnackbar({message: `${res.data?.allocateVotes} vote${res.data?.allocateVotes > 1 ? 's' : ''} have been allocated`})))
        .catch((e) => dispatch(updateSnackbar({message: e.message, isError: true})))
        .finally(() => {
          refetch({id: batch.id});
          dispatch(updateBlockScreen(false));
        });
    }
  }

  useEffect(() => {
    data?.isStudentVotesAllocated !== isVotesAllocated && setState({
      ...state,
      isVotesAllocated: data?.isStudentVotesAllocated,
    });
  }, [data, state, isVotesAllocated]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <>
        <h2>Student votes allocation</h2>
        <div>
          <p>Allocate tasks to students with state <strong>REGISTERED</strong> to vote on other <strong>REGISTERED</strong> students sharing a common primary role.</p>
          <p className={'opacity-80 text-sm'}><strong>Note:</strong> If there are less than 23 applicants for a particular role, each student may be assigned less than 10 voting tasks.</p>
        </div>
        <div>
          {
            isVotesAllocated === false && <PaddedButton label={`Allocate votes`} icon={<PlayArrow/>}
                                                        onClick={() => {setState({...state, typeInChallengeDialogIsVisible: true})}}/>
          }
          {
            isVotesAllocated && <PaddedButton label={`Delete votes`} color={'error'} icon={<Delete/>}
                                              onClick={() => {setState({...state, typeInChallengeDialogIsVisible: true})}}/>
          }
        </div>
        <TypeInChallengeDialog isVisible={!!typeInChallengeDialogIsVisible}
                               targetValue={coachEmail}
                               prompt={`Please enter your email address to proceed:`}
                               fieldName={'Email address'}
                               onClose={() => setState({...state, typeInChallengeDialogIsVisible: false})}
                               onChallengePassed={submitForm}/>
      </>
    </QueryHandlerBase>
  );
}