import React from 'react';

type SpacerProps = {
  d?: number,
  className?: string,
}

export default function Spacer({d, className}: SpacerProps) {
  return (
    <div className={className} style={{height: `calc(${d || 0} * 0.25rem)`}}/>
  );
}