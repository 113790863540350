import React from 'react';

type statSummaryProps = {
  items: {key: string, value: number, bigger?: boolean, selected?: boolean, onClick?:any}[],
}

export default function StatSummary({items}: statSummaryProps) {
  return (
    <div className={'flex gap-4 flex-wrap'}>
      {
        items.map((item) =>
            <div key={item.key} className={'p-4 pb-3 ' + (item.selected ? '' : 'bg-black bg-opacity-5 ' )+ ' rounded-md flex flex-col ' +  (item.selected ? 'text-white' : '' ) + ''}
              style={{backgroundColor: (item.selected? 'rgb(3, 41, 70)': ''), cursor: (item.onClick ? 'pointer': 'default')}}
              onClick={item.onClick}
            >
              <p className={'uppercase opacity-80 text-xs m-0 w-24'}>{item.key}</p>
              <div className={'flex flex-col gap-2 flex-grow'}>
                <div className={'flex-grow'}/>
                <h3 className={`m-0 text-right ${item.bigger ? 'text-5xl' : 'opacity-80'}`}>{item.value || '-'}</h3>
              </div>
            </div>)
      }
    </div>
  );
}