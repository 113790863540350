import React, {useEffect, useState} from 'react';
import {Alert, AlertTitle} from '@mui/material';
import {gql, useMutation, useQuery} from '@apollo/client';
import {ArrowForward, LockPerson} from '@mui/icons-material';
import {BatchX} from '../../models/BatchX';
import QueryHandlerBase from '../../components/QueryHandlerBase';
import FieldWrapper from '../../components/applicationForm/FieldWrapper';
import ApplicationFormFileUploader from '../../components/applicationForm/ApplicationFormFileUploader';
import PaddedButton from '../../components/PaddedButton';
import {FormPropEnum, FormPropLabelEnum, PageRoutingEnum} from '../../enums/Enums';
import {updateBlockScreen, updatePageTitle} from '../../store/appSlice';
import {useAppDispatch} from '../../hooks/hooks';
import TmsFormCheckbox from '../../components/TmsFormCheckbox';
import StudentVoteButton from '../../components/studentVoteButton/StudentVoteButton';
import {convertApplicationToVote} from '../../helpers/helper';
import TypeInChallengeDialog from '../../components/TypeInChallengeDialog';
import PersonalInformationModule from '../../components/applicationFormModules/PersonalInformationModule';
import {ApplicationFormType} from '../../models/ApplicationX';
import ProfilePictureModule from '../../components/applicationFormModules/ProfilePictureModule';
import ResumeModule from '../../components/applicationFormModules/ResumeModule';
import ApplicationModule from '../../components/applicationFormModules/ApplicationModule';
import {Link} from 'react-router-dom';

type State = {
  typeInChallengeDialogIsVisible?: boolean,
}

export default function PublicApply() {
  const dispatch = useAppDispatch();
  const [form, setForm] = useState<Partial<ApplicationFormType>>({noLrzId: false});
  const [batch, setBatch] = useState<Partial<BatchX>>();
  const [consentObtained, setConsentObtained] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<{[key in FormPropEnum]?: string}>();

  const [state, setState] = useState<State>({});
  const {typeInChallengeDialogIsVisible} = state;

  const [submitApplication, submitApplicationResult] = useMutation(gql`
  mutation RegisterApplication($data: ApplicationCreateInput!) {
    registerApplication(data: $data) {
      id
      student {
        id
        email
        magicUrl
      }
    }
  }
`, {variables: {
      data: {
        firstName: form.firstName,
        lastName: form.lastName,
        lrzId: form.lrzId,
        email: form.email,
        primaryRole: form.primaryRole,
        secondaryRole: form.secondaryRole,
        motivationText: form.motivationText,
        applicationLink: form.applicationLink,
        imgUrl: form.imgUrl,
        imgFilesize: +(form.img?.size || 0),
        applicationFilename: form.applicationFilename,
        applicationFilesize: +(form.applicationFile?.size || 0),
        cvFilename: form.cvFilename,
        cvFilesize: +(form.cv?.size || 0),
        noLrzId: form.noLrzId,
        faculty: form.faculty,
      },
    }});

  const {loading, error, data} = useQuery(gql`
    query Query {
      openBatch {
        id
        batchNumber
        semester
      }
    } 
  `);

  const updateForm = (value: Partial<ApplicationFormType>) => {
    if (value.noLrzId !== undefined) {
      if (value.noLrzId) {
        value = {...value, lrzId: undefined};
      } else {
        value = {...value, email: undefined};
      }
    }

    if (value.lrzId) {
      // Override email address
      value = {...value, email: `${value.lrzId}@mytum.de`};
    } else if (value.lrzId === '') {
      // Reset overridden email address
      value = {...value, email: ''};
    }

    setForm({...form, ...value});

    const errs = {...validationErrors};
    for (const key in value) {
      // @ts-ignore
      delete errs[key];
      setValidationErrors(errs);
    }
    if (Object.keys(errs).length === 0) {
      setShowErrorMessage(false);
    }
  };

  const submitForm = () => {
    dispatch(updateBlockScreen(true));
    submitApplication()
      .catch(() => window.scrollTo(0, 0))
      .finally(() => dispatch(updateBlockScreen(false)));
    setState({...state, typeInChallengeDialogIsVisible: false});
  }

  useEffect(() => {
    const errors: unknown = submitApplicationResult.error?.graphQLErrors[0]?.extensions?.validationErrors;
    if (errors) setValidationErrors(errors ? errors : undefined);
    setShowErrorMessage(true);
    setConsentObtained(false);
  }, [submitApplicationResult.error?.graphQLErrors]);

  useEffect(() => {
    setBatch(data?.openBatch);
  }, [data]);

  useEffect(() => {
    dispatch(updatePageTitle('Apply Now'));
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col gap-4 flex-wrap'}>
        {
          data?.openBatch === null &&
          <>
            <h1>Thank you for your interest in Think. Make. Start.</h1>
            <div>
              <p>Unfortunately, we are not accepting applications at the moment.</p>
              <p>Sign up <Link to={`/${PageRoutingEnum.PUBLIC_SUBSCRIBE}`}>here</Link> to get updated on the next TMS events.</p>
            </div>
          </>
        }
        {
          data?.openBatch &&
          <>
            {
              submitApplicationResult.data &&
              <ApplicationFormFileUploader profile={form.img} applicationFile={form.applicationFile}
                                           application={submitApplicationResult.data.registerApplication}
                                           cv={form.cv}/>
            }
            {
              !submitApplicationResult.data && batch &&
              <>
                <FieldWrapper>
                  <h1>Apply for Batch {batch.batchNumber} ({batch.semester})</h1>
                </FieldWrapper>
                <FieldWrapper>
                  <div>
                    <p>
                     Welcome to Think. Make. Start.</p>
                     <p>We are looking forward to your application. Choose a team role and come up with a convincing application to show your peers that you are the right person for TMS. Your application will compete against all the other applications of the same role! Compose what you think expresses best who you are and what you do.
                    After your application is complete, you get to review and rank other applicants: Who would you consider as a valuable course participant? So this is it, truly democratic: everyone votes and gets voted on. Plus, you already get to know the people you really want to have in your team  (because, pssst…the team is everything!!!).
                    </p>

  <p>Information you provide for fields marked with the lock icon (<LockPerson/>) are used for internal processing only and will not be shared with your peers.</p>
                  <p><strong>You can find a preview of your application at the bottom of this form.</strong></p>
                  </div>
                </FieldWrapper>
                {
                  submitApplicationResult?.error && showErrorMessage ?
                    <FieldWrapper>
                      <Alert severity={'error'}>
                        <AlertTitle>Your application could not be submitted</AlertTitle>
                        <p dangerouslySetInnerHTML={{__html: submitApplicationResult.error.message}}></p>
                        {
                          validationErrors && Object.keys(validationErrors).length > 0 &&
                          <>
                            <p>Please update your input for the following field{Object.keys(validationErrors).length > 1 ? 's' : ''}:</p>
                            <ul>
                              {
                                Object.keys(validationErrors).map((e) => <li key={e}>{FormPropLabelEnum[e as FormPropEnum]}</li>)
                              }
                            </ul>
                          </>
                        }
                      </Alert>
                    </FieldWrapper> : null
                }
                <PersonalInformationModule form={form} onFormUpdated={(o) => updateForm(o)} validationErrors={validationErrors}/>
                <ProfilePictureModule form={form} onFormUpdated={(o) => updateForm(o)} validationErrors={validationErrors}/>
                <ResumeModule form={form} onFormUpdated={(o) => updateForm(o)} validationErrors={validationErrors}/>
                <ApplicationModule form={form} onFormUpdated={(o) => updateForm(o)} validationErrors={validationErrors}/>
                <h2>Preview</h2>
                <FieldWrapper description={'This is how your entry would appear to other applicants during peer voting.'}>
                  <StudentVoteButton vote={convertApplicationToVote(form, batch)} arraySize={1} profilePictureFile={form.img}
                                     suppFile={form.applicationFile} onTriggerRefetch={() => {}} lastFetch={new Date()} isVotesLocked={false}/>
                </FieldWrapper>
                <h2>Form submission</h2>
                <FieldWrapper>
                  <TmsFormCheckbox checked={consentObtained}
                                   label={'I hereby consent to data processing carried out by the Technical University of Munich.'}
                                   onClick={() => setConsentObtained(!consentObtained)}/>
                </FieldWrapper>
                <FieldWrapper className={'mt-2'}
                  // description={'You can make changes to your application after submitting, before the registration period closes.'}
                >
                  <PaddedButton label={'Submit'} icon={<ArrowForward/>} disabled={!consentObtained} className={'w-full'}
                                trailingIcon
                                onClick={() => {
                                  if (form.lrzId || form.email) {
                                    setState({...state, typeInChallengeDialogIsVisible: true});
                                  } else {
                                    submitForm();
                                  }
                                }}
                  />
                </FieldWrapper>
                <TypeInChallengeDialog isVisible={!!typeInChallengeDialogIsVisible}
                                       targetValue={form.lrzId || form.email || ''}
                                       prompt={`Please re-enter your ${form.lrzId ? 'LRZ/TUM ID' : 'email address'} to proceed:`}
                                       fieldName={form.lrzId ? FormPropLabelEnum.lrzId : FormPropLabelEnum.email}
                                       onClose={() => setState({...state, typeInChallengeDialogIsVisible: false})}
                                       onChallengePassed={submitForm}/>
              </>
            }
          </>
        }
      </div>
    </QueryHandlerBase>
  );
}