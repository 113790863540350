import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {ApplicationStateEnum, BroadcastEmailEnum} from '../../enums/Enums';
import PaddedButton from '../PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import TmsFormDropdown from '../TmsFormDropdown';
import {InputAdornment, MenuItem, TextField} from '@mui/material';
import {BatchX} from '../../models/BatchX';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import TypeInChallengeDialog from '../TypeInChallengeDialog';
import {gql, useMutation} from '@apollo/client';
import {updateBlockScreen, updateSnackbar} from '../../store/appSlice';
import Spacer from '../Spacer';

type broadcastDialogProps = {
  isVisible: boolean,
  onClose: () => void,
  stateFilter?: {[key in ApplicationStateEnum]?: boolean},
  batch: BatchX,
  coachEmail: string,
}

interface BroadcastForm {
  title: string,
  message: string,
  headerGreeting?: string,
  footerSignoff?: string,
  footerSender?: string,
}

type State = {
  emailType?: BroadcastEmailEnum,
  form?: Partial<BroadcastForm>,
  typeInChallengeDialogIsVisible?: boolean,
  validationErrors?: {[key in keyof BroadcastForm]?: string},
}

export default function BroadcastDialog({isVisible, onClose, stateFilter, batch, coachEmail}: broadcastDialogProps) {
  const dispatch = useAppDispatch();
  const [state, setState] = useState<State>({emailType: BroadcastEmailEnum.FREE_TEXT});
  const {emailType, form, typeInChallengeDialogIsVisible, validationErrors} = state;
  const {magicUrl} = useAppSelector((state) => state.app);

  const [postMutation] = useMutation(gql`
    mutation Mutation ($token: String!, $batchId: Int!, $states: String, $emailType: BroadcastEmailNexusEnum!, $title: String, $message: String, $headerGreeting: String, $footerSignoff: String, $footerSender: String) {
      broadcastByState (token: $token, batchId: $batchId, states: $states, emailType: $emailType, title: $title, message: $message, headerGreeting: $headerGreeting, footerSignoff: $footerSignoff, footerSender: $footerSender)
    }
  `, {variables: {
      token: magicUrl,
      batchId: batch.id,
      states: Object.keys(stateFilter || {}).join(','),
      emailType,
      title: form?.title,
      message: form?.message,
      headerGreeting: form?.headerGreeting,
      footerSignoff: form?.footerSignoff,
      footerSender: form?.footerSender,
    }});

  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };

  const updateForm = (obj: Partial<BroadcastForm>) => {
    const keys = Object.keys(obj) as (keyof BroadcastForm)[];
    const veCopy = {...validationErrors};
    for (const k of keys) delete veCopy[k];
    setState({...state, form: {...form, ...obj}, validationErrors: veCopy});
  };

  const submitForm = () => {
    dispatch(updateBlockScreen(true));
    postMutation()
      .then((res) => {
        onClose();
        const count = res.data.broadcastByState;
        dispatch(updateSnackbar({message: `Email was sent to ${count} recipient${count > 1 ? 's' : ''}`
      }));
      })
      .catch((e) => {
        dispatch(updateSnackbar({message: e.message, isError: true}));
        updateState({validationErrors: e.graphQLErrors[0]?.extensions?.validationErrors});
      })
      .finally(() => dispatch(updateBlockScreen(false)));
  };

  return (
    <>
      <Dialog open={isVisible} onClose={onClose}>
        <div className={'p-4 md:p-6'}>
          <div className={'flex flex-col gap-4 max-w-[500px]'}>
            <h3>Send bulk emails</h3>
            <TmsFormDropdown label={'Template'} value={emailType} className={'flex-grow'}
                             onChange={(e) => updateState({emailType: e.target.value as BroadcastEmailEnum})}>
              {
                Object.keys(BroadcastEmailEnum).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)
              }
            </TmsFormDropdown>
            {
              emailType === BroadcastEmailEnum.FREE_TEXT &&
              <div className={'flex flex-col gap-2'}>
                <h4>Email</h4>
                <div className={'rounded-md p-4 text-sm bg-black bg-opacity-5 flex flex-col gap-1'}>
                  <p className={'font-medium'}>Tokens</p>
                  {
                    [
                      {'firstName': 'User\'s first name'},
                      {'lastName': 'User\'s last name'},
                      {'token': 'User\'s private token. Can be used to build login URL.'},
                    ].map((e, ix) =>
                      <div key={ix} className={'flex flex-wrap gap-2 justify-start items-center text-xs'}>
                        <pre className={'p-1 px-1.5 border bg-white rounded-md'}>#&#123;{Object.keys(e)[0]}&#125;</pre>
                        <span>{Object.values(e)[0]}</span>
                      </div>
                    )
                  }
                </div>
                <Spacer/>
                <TextField label={'Subject'} variant={'filled'} value={form?.title || ''} error={!!validationErrors?.title}
                           helperText={validationErrors?.title} fullWidth
                           onChange={(e) => updateForm({title: e.target.value})}
                           InputProps={{
                             startAdornment: <InputAdornment position="start">TMS #{batch.batchNumber} -</InputAdornment>,
                           }}/>
                <Spacer/>
                <TextField label={'Greeting (optional)'} variant={'filled'} value={form?.headerGreeting || ''} placeholder={'Hello #{firstName},'} onChange={(e) => updateForm({headerGreeting: e.target.value})}/>
                <TextField label={'Message'} error={!!validationErrors?.message} helperText={validationErrors?.message} variant={'filled'} rows={5} value={form?.message || ''} onChange={(e) => updateForm({message: e.target.value})} multiline fullWidth/>
                <TextField label={'Sign off (optional)'} variant={'filled'} value={form?.footerSignoff || ''} placeholder={'Best regards,'}  onChange={(e) => updateForm({footerSignoff: e.target.value})}/>
                <TextField label={'Sender name (optional)'} variant={'filled'} value={form?.footerSender || ''} placeholder={'Your TMS organizers'} onChange={(e) => updateForm({footerSender: e.target.value})}/>
              </div>
            }
            <Spacer/>
            <p className={'mb-3'}>You are about to send an email to all applicants{Object.keys(stateFilter || {}).length === 0 ? '' : ' with state: '}<strong>{Object.keys(stateFilter || {}).join(',')}</strong>. Emails cannot be withdrawn once they are sent out.</p>
            <div className={'flex justify-end w-full gap-2 flex-wrap'}>
              <PaddedButton label={'Cancel'} color={'secondary'} onClick={onClose} growOnMobile/>
              <PaddedButton label={'Send'} icon={<ArrowForward/>} trailingIcon growOnMobile onClick={() => updateState({typeInChallengeDialogIsVisible: true})}/>
            </div>
          </div>
        </div>
      </Dialog>
      <TypeInChallengeDialog isVisible={typeInChallengeDialogIsVisible || false}
                             prompt={'Please enter your email address to proceed:'}
                             onChallengePassed={submitForm} fieldName={'Email address'} targetValue={coachEmail}
                             onClose={() => updateState({typeInChallengeDialogIsVisible: !typeInChallengeDialogIsVisible})}/>
    </>
  );
}