import React from 'react';
import {ApolloError} from '@apollo/client';
import LoadWheel from './LoadWheel';
import BasicError from './BasicError';

type FullPageQueryHandlerProps = {
  loading?: boolean,
  loadScreen?: JSX.Element,
  error?: ApolloError,
  errorScreen?: JSX.Element,
  children: JSX.Element,
}

export default function QueryHandlerBase({loading, loadScreen, error, errorScreen, children}: FullPageQueryHandlerProps) {
  return (
    <>
      {
        loading && !loadScreen && <LoadWheel/>
      }
      {
        loading && loadScreen
      }
      {
        error && !errorScreen && <BasicError error={error}/>
      }
      {
        error && errorScreen
      }
      {
        !loading && !error && children
      }
    </>
  );
}