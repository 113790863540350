export const facultyList = [
  'TUM School of Computation, Information and Technology',
  'TUM School of Engineering and Design',
  'TUM School of Natural Sciences',
  'TUM School of Life Sciences',
  'TUM School of Medicine and Health',
  'TUM School of Management',
  'TUM School of Social Sciences and Technology',
  'Other (TUM)', 
  'Other (external)'
];

/*
export const facultyList = [
  'Aerospace and Geodesy',
  'Architecture',
  'Chemistry',
  'Civil, Geo and Environmental Engineering',
  'Electrical and Computer Engineering',
  'Informatics',
  'Mathematics',
  'Mechanical Engineering',
  'Physics',
  'Sport and Health Sciences',
  'TUM School of Education',
  'TUM School of Governance',
  'TUM School of Life Sciences',
  'TUM School of Management',
  'TUM School of Medicine',
  'Other (TUM)',
  'Other (external)'
];*/
