export const LIPSUM_50 = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas molestie metus non aliquet lobortis. Nam non nibh vel nisi pulvinar sagittis luctus vel magna. Nam viverra placerat dui, sed lobortis dui. Duis dignissim varius tincidunt. Ut luctus ex sit amet arcu scelerisque, eget luctus arcu accumsan. Aliquam sollicitudin sit amet.';

export enum ApplicationStateEnum {
  REGISTERED = 'REGISTERED',
  HOLD = 'HOLD',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum StudentRoleEnum {
  PROBLEM_EXPERT = 'PROBLEM_EXPERT',
  TECH_DEVELOPER = 'TECH_DEVELOPER',
  BUSINESS_DEVELOPER = 'BUSINESS_DEVELOPER',
  UNDEFINED = 'UNDEFINED', // Always the last
}

export enum StudentRoleLabelEnum {
  PROBLEM_EXPERT = 'Problem Expert',
  TECH_DEVELOPER = 'Tech Developer',
  BUSINESS_DEVELOPER = 'Business Developer',
  UNDEFINED = 'Role', // Always the last
}

export enum StudentRoleDescEnum {
  PROBLEM_EXPERT = 'Your focus is on understanding of potentials, customer needs, challenge assumptions and asking a lot of questions.',
  TECH_DEVELOPER = 'Your focus is on prototyping, experiments, making things work and usability testing.',
  BUSINESS_DEVELOPER = 'Your focus is on sustainable business models, analysing markets, customers and finances and scaleability.',
  UNDEFINED = 'This is an undefined role, please exclude this option from ever being rendered using the .slice(0, -1) function.',   // Always the last
}

export enum PageRoutingEnum {
  PUBLIC_OUTLET = '',
  PUBLIC_HOME = '',
  PUBLIC_APPLY = 'apply',
  PUBLIC_FAQ = 'faq',
  PUBLIC_SUBSCRIBE = 'subscribe',
  PUBLIC_UNSUBSCRIBE = 'unsubscribe',
  STUDENT_OUTLET = 'student',
  STUDENT_HOME = '',
  STUDENT_ACCOUNT = 'account',
  STUDENT_APPLICATION = 'applications',
  STUDENT_PEER_VOTING = 'votes',
  COACH_OUTLET = 'coach',
  COACH_HOME = '',
  COACH_BATCH_ADMIN = 'admin',
  COACH_OVERVIEW = 'overview',
  COACH_APPLICATIONS = 'applications',
  COACH_VOTING = 'voting',
  PAGE_NOT_FOUND = '*',
}

export enum FormPropEnum {
  applicationFilename = 'applicationFilename',
  applicationLink = 'applicationLink',
  motivationText = 'motivationText',
  secondaryRole = 'secondaryRole',
  primaryRole = 'primaryRole',
  imgUrl = 'imgUrl',
  faculty = 'faculty',
  lastName = 'lastName',
  firstName = 'firstName',
  cvFilename = 'cvFilename',
  email = 'email',
  lrzId = 'lrzId',
}

export enum FormPropLabelEnum {
  cvFilename = 'Resume',
  applicationFilename = 'Application document',
  email = 'Email address',
  firstName = 'First name',
  lastName = 'Last name',
  primaryRole = 'Primary role',
  secondaryRole = 'Secondary role',
  motivationText = 'Motivation text',
  faculty = 'Faculty',
  imgUrl = 'Profile picture',
  lrzId = 'LRZ/TUM ID',
  applicationLink = 'Application link',
}

export enum BroadcastEmailEnum {
  FREE_TEXT = 'FREE_TEXT',
  VOTING_OPEN = 'VOTING_OPEN',
  ACCEPT_PLEASE_CONFIRM = 'ACCEPT_PLEASE_CONFIRM',
  REJECT = 'REJECT',
}