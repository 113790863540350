import React, {useEffect} from 'react';
import {updatePageTitle} from '../../store/appSlice';
import {useAppDispatch} from '../../hooks/hooks';

export default function PublicFaq() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updatePageTitle('Frequently Asked Questions (FAQs)'));
    return (() => {
      dispatch(updatePageTitle(undefined));
    });
  }, [dispatch]);

  return (
    <>
      <h1>Frequently Asked Questions (FAQs)</h1>
    </>
  );
}