import React from 'react';
import {FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent} from '@mui/material';

type SelectDropdownProps = {
  value?: string,
  label: string,
  children: JSX.Element[],
  onChange?: (e: SelectChangeEvent) => void,
  disabled?: boolean,
  fullWidth?: boolean,
  error?: boolean,
  helperText?: string,
  defaultValue?: string,
  className?: string,
}

export default function TmsFormDropdown({label, onChange, value, children, className, disabled, fullWidth, error, helperText, defaultValue}: SelectDropdownProps) {
  return (
    <FormControl fullWidth={fullWidth} error={error} className={className}>
      <InputLabel variant={'filled'}>{label}</InputLabel>
      <Select label={label} variant={'filled'} value={value} disabled={disabled}
              onChange={(e) => onChange && onChange(e)} defaultValue={defaultValue}>
        {/*<MenuItem value={undefined} className={'opacity-50 pointer-events-none'}>Please select an option</MenuItem>*/}
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}