import React from 'react';
import {StudentRoleDescEnum, StudentRoleEnum, StudentRoleLabelEnum} from '../../enums/Enums';

type StudentRoleButtonProps = {
  role: StudentRoleEnum,
  onClick: () => void,
  selection?: string,
  disabled?: boolean,
  summary?: boolean,
}

export default function StudentRoleButton({role, onClick, selection, disabled, summary}: StudentRoleButtonProps) {
  return (
    <div className={'relative'}>
      <button className={'before:absolute before:w-full before:h-full before:rounded-md before:top-0 before:left-0 ' +
                'before:transition-all before:duration-100 p-4 text-left w-full rounded-md ' +
                `${disabled ? 'text-neutral-300 cursor-not-allowed' : ''} ` +
                `${selection === role ? 'before:border-2 before:bg-opacity-0 before:border-tms-blue before:bg-tms-blue' : 'before:border'}`}
              onClick={() => onClick()} disabled={disabled}>
        <p className={`${selection === role ? 'text-tms-blue' : ''}` +
          ` m-0 transition-color duration-100 ${!summary ? 'text-lg' : 'text-md'}`}>
          <strong>{StudentRoleLabelEnum[role]}</strong></p>
        {
          !summary ? <p className={'m-0 mt-1'}>{StudentRoleDescEnum[role]}</p> : null
        }
      </button>
    </div>
  );
}