import React from 'react';
import {Alert, AlertTitle} from '@mui/material';
import {ApolloError} from '@apollo/client';

type basicErrorProps = {
  error?: ApolloError,
  message?: string,
  header?: string,
}

export default function BasicError({error, header, message}: basicErrorProps) {
  return (
    <Alert severity={'error'}>
      <AlertTitle>{header || 'Something went wrong'}</AlertTitle>
      <span>{message || error?.message || 'Please try again later'}</span>
    </Alert>
  );
}