import React from 'react';

type StudentRoleButtonWrapperProps = {
  children: JSX.Element,
  error?: boolean,
  helperText?: string,
  className?: string,
}

export default function FormButtonWrapper({children, error, helperText, className}: StudentRoleButtonWrapperProps) {
  return (
    <>
      <div className={className + ' flex flex-col gap-2 p-2 before:rounded-md relative before:absolute ' +
        'before:top-0 before:left-0 before:h-full before:w-full before:transition-all before:duration-300 before:z-0 before:pointer-events-none ' +
        `${error ? 'before:border-mui-error-main before:border-2' : 'before:border'}`}>
        {children}
      </div>
      {
        helperText ? <p className={`text-[0.75rem] mt-1.5 px-4 ` +
         `${error ? 'text-mui-error-main' : 'text-neutral-500'}`}>{helperText}</p> : null
      }
    </>
  );
}