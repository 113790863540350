import React from 'react';
import {ApplicationStateEnum} from '../enums/Enums';

type ApplicationStateProps = {
  label?: ApplicationStateEnum,
  className?: string,
}

export default function ApplicationState({label, className}: ApplicationStateProps) {
  const labelDict: {[key in ApplicationStateEnum]: string} = {
    'HOLD': 'Pending',
    'REGISTERED': 'Registered',
    'REJECT': 'Not selected',
    'ACCEPT': 'Accepted',
    'CONFIRMED': 'Confirmed',
    'CANCELLED': 'Withdrawn',
    'INCOMPLETE': 'Incomplete',
  }

  return (
    <>
      {
        label ? <span className={`${className || ''} p-2 py-1 rounded-lg uppercase bg-opacity-0 border-2 whitespace-nowrap ` +
          `${[ApplicationStateEnum.REJECT, ApplicationStateEnum.CANCELLED, ApplicationStateEnum.INCOMPLETE].includes(label) ? 'border-gray-500 bg-gray-500 text-gray-500' : ''}` +
          `${label === ApplicationStateEnum.HOLD ? 'border-mui-warning-main bg-mui-warning-main text-mui-warning-main' : ''}` +
          `${[ApplicationStateEnum.ACCEPT, ApplicationStateEnum.CONFIRMED].includes(label) ? 'border-mui-success-main bg-mui-success-main text-mui-success-main' : ''}` +
          `${label === ApplicationStateEnum.REGISTERED ? 'border-tms-blue bg-tms-blue text-tms-blue' : ''}`
        }>
          <strong>{labelDict[label]}</strong>
        </span> : null
      }
    </>
  );
}