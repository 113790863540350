import React from 'react';
import WidthWrapper from './WidthWrapper';
import QueryHandlerBase from './QueryHandlerBase';
import {ApolloError} from '@apollo/client';
import Header from './Header';
import BasicError from './BasicError';

type SidebarAndOutletProps = {
  loading?: boolean,
  error?: ApolloError,
  errorScreen?: JSX.Element,
  loadScreen?: JSX.Element,
  sidebar?: JSX.Element,
  outlet: JSX.Element,
  header?: JSX.Element,
}

export default function OutletWithSidebar({loading, error, errorScreen, loadScreen, sidebar, outlet, header}: SidebarAndOutletProps) {
  const defaultErrorScreen = (
    <>
      {
        errorScreen && errorScreen
      }
      {
        !errorScreen && <div className={'w-full'}>
          <BasicError error={error}/>
        </div>
      }
    </>
  );

  return (
    <>
      {header || <Header/>}
      <WidthWrapper>
        <QueryHandlerBase loading={loading} error={error} errorScreen={defaultErrorScreen} loadScreen={loadScreen}>
          <div className={'flex gap-8 pb-20'}>
            {sidebar}
            <div className={`flex-grow ${sidebar ? 'lg:pr-4' : ''}`}>
              {outlet}
            </div>
          </div>
        </QueryHandlerBase>
      </WidthWrapper>
    </>
  );
}