import React, {useEffect, useState} from 'react';
import {updatePageTitle} from '../../../store/appSlice';
import {useAppDispatch, useAppSelector} from '../../../hooks/hooks';
import {gql, useQuery} from '@apollo/client';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import BatchSelector from '../../../components/BatchSelector';
import {CoachRoleForCoachSelf} from '../../../models/CoachRoleX';
import {CoachForCoachSelf} from '../../../models/CoachX';
import CoachVotingContent from "../voting/CoachVotingContent";

type State = {
  coach?: CoachForCoachSelf,
  selectedCoachRole?: CoachRoleForCoachSelf,
}

export default function CoachOverviewHome() {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({});
  const {selectedCoachRole, coach} = state;

  const {loading, data, error} = useQuery(gql`
    query Query ($magicUrl: String!) {
      coachByMagicUrl (magicUrl:$magicUrl) {
        id
        email
        coachRoles {
          id
          batch {
            id
            batchNumber
            semester
          }
        }
      }
    }
  `, {variables: {magicUrl}, fetchPolicy: 'network-only'});

  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };

  useEffect(() => {
    updateState({
      coach: data?.coachByMagicUrl,
      selectedCoachRole: data?.coachByMagicUrl?.coachRoles[0],
    });
  }, [data]);

  useEffect(() => {
    dispatch(updatePageTitle('Coach Voting'));
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col gap-4'}>
        {
          coach && coach.coachRoles.length > 0 && selectedCoachRole && coach &&
          <>
            {
              coach.coachRoles.length > 1 &&
              <BatchSelector selectedBatch={selectedCoachRole.batch} batches={coach.coachRoles.map((b) => b.batch)}
                             onChange={(e) => {setState({...state, selectedCoachRole: coach.coachRoles.find((el) => el.batch.id === Number.parseInt(e.target.value)) || undefined})}}/>
            }
            {
              coach.coachRoles.length === 1 && <h1>Batch {selectedCoachRole.batch.batchNumber} ({selectedCoachRole.batch.semester})</h1>
            }
            {
              selectedCoachRole &&
              <CoachVotingContent batchId={selectedCoachRole.batch.id}/>
            }
          </>
        }
      </div>
    </QueryHandlerBase>
  );
}