import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import PaddedButton from './PaddedButton';
import {ArrowForward} from '@mui/icons-material';
import {useState} from 'react';
import Spacer from './Spacer';

type typeInChallengeDialogProps = {
  isVisible: boolean,
  onClose: () => void,
  onChallengePassed: () => void,
  header?: string,
  prompt?: string,
  fieldName: string,
  targetValue: string,
}

type State = {
  value?: string,
}

export default function TypeInChallengeDialog({isVisible, prompt, header, fieldName, targetValue, onClose, onChallengePassed}: typeInChallengeDialogProps) {
  const [state, setState] = useState<State>({});
  const {value} = state;

  const closeHandler = () => {
    onClose();
    setState({...state, value: undefined});
  };

  const submitHandler = () => {
    if (value === targetValue) {
      closeHandler();
      onChallengePassed();
    }
  };

  return (
    <Dialog open={isVisible} onClose={closeHandler}>
      <div className={'p-4 md:p-6'}>
        <div className={'flex flex-col gap-4'}>
          <h3>{header || 'Before we continue'}</h3>
          <div className={'flex flex-col items-center gap-2'}>
            <p>{prompt || 'Please enter the value below to proceed:'}</p>
            <div>
              <pre className={'border rounded-md p-2 inline'}>{targetValue}</pre>
            </div>
          </div>
          <Spacer/>
          <TextField autoFocus label={fieldName} fullWidth variant={'filled'}
                     value={value || ''} onChange={(e) => setState({...state, value: e.target.value.toLowerCase().replaceAll(' ', '')})}/>
          <Spacer/>
          <div className={'flex gap-2 justify-end flex-wrap'}>
            <PaddedButton label={'Cancel'} color={'secondary'} onClick={closeHandler} growOnMobile/>
            <PaddedButton label={'Submit'} onClick={submitHandler} icon={<ArrowForward/>} trailingIcon disabled={targetValue !== value} growOnMobile/>
          </div>
        </div>
      </div>
    </Dialog>
  );
}