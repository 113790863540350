import React, {useEffect, useState} from 'react';
import StatSummary from '../../../components/StatSummary';
import {StudentRoleLabelEnum} from '../../../enums/Enums';
import {gql, useQuery} from '@apollo/client';
import {useAppSelector} from '../../../hooks/hooks';
import QueryHandlerBase from '../../../components/QueryHandlerBase';
import StudentVoteButton from '../../../components/studentVoteButton/StudentVoteButton';
import FormButtonWrapper from '../../../components/applicationForm/FormButtonWrapper';


type coachOverviewContentProps = {
  batchId: number,
}

type State = {
  coach?: any,
}

export default function CoachApplicationsContent({batchId}: coachOverviewContentProps) {
    const {magicUrl} = useAppSelector((state) => state.app);
    const [state, setState] = useState<State>({});
    const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));
    const {coach} = state;

    const {loading, data, error} = useQuery(gql`
    query Query ($magicUrl: String!, $batchId: Int!) {
      coachByMagicUrl (magicUrl:$magicUrl) {
        id
        email
        coachRoles (batchId: $batchId) {
          id
          isAdmin
          batch {
            id
            batchNumber
            semester
            sumApplications
            sumPe
            sumTd
            sumBd
            applications {
              id
              primaryRole
              secondaryRole
              motivationText
              applicationLink
              applicationFilename
              cvFilename
              student {
                id
                firstName
                lastName
                imgUrl
                faculty
              }
            }
          }
        }
      }
    }
    `, {variables: {magicUrl, batchId}, fetchPolicy: 'network-only'});

    const applicationToVote = (i: number, application: any)  => {
        return {
            id: i,
            batchId: 0,
            senderId: '',
            sender: application,
            recipient: application,
            recipientId: '',
            point: 1,
            initPoint: 1,
            lockedDt: 1,
        };
    }

    useEffect(() => {
        updateState({coach: data?.coachByMagicUrl});
    }, [data]);

    let applications = (coach?.coachRoles.length > 0) ? coach?.coachRoles[0].batch.applications : [];
    const [filter, setFilter] = useState('all');

    if (filter !== 'all') {
        applications = applications.filter((x:any) => x.primaryRole === filter);
    }

    const PROBLEM_EXPERT = 'PROBLEM_EXPERT';
    const TECH_DEVELOPER = 'TECH_DEVELOPER';
    const BUSINESS_DEVELOPER = 'BUSINESS_DEVELOPER';

    applications = applications.toSorted((a:any, b:any) => {
        const getScore = (role:string) => {
            if(role === PROBLEM_EXPERT) return 1;
            else if(role === TECH_DEVELOPER) return 2;
            else return 3;
        }
        if (a.primaryRole !== b.primaryRole) {
            return getScore(a.primaryRole) > getScore(b.primaryRole)? 1 : -1;
        } else {
            return a.student.firstName > b.student.firstName? 1 : -1;
        }
    });

    return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col items-start gap-4'}>
        {
          coach?.coachRoles &&
          <>
            <h2>All applicants</h2>
            <StatSummary items={[
              {key: 'Total', value: coach.coachRoles[0].batch.sumApplications, bigger: true, selected: (filter === 'all'), onClick:()=>setFilter('all')},
              {key: StudentRoleLabelEnum.PROBLEM_EXPERT, value: coach.coachRoles[0].batch.sumPe, selected: (filter === PROBLEM_EXPERT), onClick:()=>setFilter(PROBLEM_EXPERT)},
              {key: StudentRoleLabelEnum.TECH_DEVELOPER, value: coach.coachRoles[0].batch.sumTd, selected: (filter === TECH_DEVELOPER), onClick:()=>setFilter(TECH_DEVELOPER)},
              {key: StudentRoleLabelEnum.BUSINESS_DEVELOPER, value: coach.coachRoles[0].batch.sumBd, selected: (filter === BUSINESS_DEVELOPER), onClick:()=>setFilter(BUSINESS_DEVELOPER)},
            ]}/>
            <FormButtonWrapper>
                <>
                    {applications.map((x:any, i:number)=>{
                      x = applicationToVote(i, x);
                       return (<StudentVoteButton key={x.id} showCv={true} vote={x} arraySize={x.id} onTriggerRefetch={() => {}} lastFetch={new Date()} isVotesLocked={true}/>);
                    })}
                </>
            </FormButtonWrapper>
          </>
        }
      </div>
    </QueryHandlerBase>
    );
}