import React from 'react';

type openInExternalProps = {
  href: string,
  children: JSX.Element,
  disabled?: boolean,
}

export default function LinkNewTab({href, children, disabled}: openInExternalProps) {
  return (
    <a href={href} target={'blank'} rel={'noreferrer'} className={disabled ? 'pointer-events-none' : ''}>{children}</a>
  );
}