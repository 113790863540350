import React from 'react';
import PublicHome from './pages/public/PublicHome';
import {Route, Routes} from 'react-router';
import PublicApply from "./pages/public/PublicApply";
import StudentOutlet from './pages/student/StudentOutlet';
import StudentAccount from './pages/student/StudentAccount';
import StudentApplication from './pages/student/StudentApplication';
import StudentVotingBatchSelector from './pages/student/studentVoting/StudentVotingBatchSelector';
import StudentHome from './pages/student/StudentHome';
import PublicFaq from './pages/public/PublicFaq';
import CoachOutlet from './pages/coach/CoachOutlet';
import CoachHome from './pages/coach/CoachHome';
import PageNotFound from './pages/404PageNotFound';
import {useAppSelector} from './hooks/hooks';
import AppBlockScreen from './components/AppBlockScreen';
import AppSnackbar from './components/AppSnackbar';
import CoachBatchesHome from './pages/coach/batches/CoachBatchesHome';
import CoachOverviewHome from './pages/coach/overview/CoachOverviewHome';
import CoachApplicationsHome from './pages/coach/applications/CoachApplicationsHome';
import CoachVotingHome from './pages/coach/voting/CoachVotingHome'
import { PageRoutingEnum } from './enums/Enums';
import PublicOutlet from './pages/public/PublicOutlet';
import PublicSubscribe from './pages/public/PublicSubscribe';
import PublicUnsubscribe from './pages/public/PublicUnsubscribe';

function App() {
  const {blockScreen, snackbar} = useAppSelector((state) => state.app);

  return (
    <>
      <AppBlockScreen isVisible={blockScreen > 0}/>
      {
        snackbar.message && <AppSnackbar isVisible={!!snackbar.message}/>
      }
      <Routes>
        <Route path={PageRoutingEnum.PUBLIC_OUTLET} element={<PublicOutlet/>}>
          <Route path={PageRoutingEnum.PUBLIC_HOME} element={<PublicHome/>}/>
          <Route path={PageRoutingEnum.PUBLIC_APPLY} element={<PublicApply/>}/>
          <Route path={PageRoutingEnum.PUBLIC_FAQ} element={<PublicFaq/>}/>
          <Route path={PageRoutingEnum.PUBLIC_SUBSCRIBE} element={<PublicSubscribe/>}/>
          <Route path={PageRoutingEnum.PUBLIC_UNSUBSCRIBE} element={<PublicUnsubscribe/>}/>
        </Route>
        <Route path={`${PageRoutingEnum.COACH_OUTLET}`} element={<CoachOutlet/>}/>
        <Route path={`${PageRoutingEnum.COACH_OUTLET}/:magicUrl`} element={<CoachOutlet/>}>
          <Route path={PageRoutingEnum.COACH_HOME} element={<CoachHome/>}/>
          <Route path={PageRoutingEnum.COACH_BATCH_ADMIN} element={<CoachBatchesHome/>}/>
          <Route path={PageRoutingEnum.COACH_OVERVIEW} element={<CoachOverviewHome/>}/>
          <Route path={PageRoutingEnum.COACH_APPLICATIONS} element={<CoachApplicationsHome/>}/>
          <Route path={PageRoutingEnum.COACH_VOTING} element={<CoachVotingHome/>}/>
        </Route>
        <Route path={`${PageRoutingEnum.STUDENT_OUTLET}`} element={<StudentOutlet/>}/>
        <Route path={`${PageRoutingEnum.STUDENT_OUTLET}/:magicUrl`} element={<StudentOutlet/>}>
          <Route path={PageRoutingEnum.STUDENT_HOME} element={<StudentHome/>}/>
          <Route path={PageRoutingEnum.STUDENT_ACCOUNT} element={<StudentAccount/>}/>
          <Route path={PageRoutingEnum.STUDENT_APPLICATION} element={<StudentApplication/>}/>
          <Route path={PageRoutingEnum.STUDENT_PEER_VOTING} element={<StudentVotingBatchSelector/>}/>
        </Route>
        <Route path={PageRoutingEnum.PAGE_NOT_FOUND} element={<PageNotFound/>}/>
      </Routes>
    </>
  );
}

export default App;
