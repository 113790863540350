import React, {useEffect, useState} from 'react';
import {updatePageTitle} from '../../store/appSlice';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {gql, useQuery} from '@apollo/client';
import QueryHandlerBase from '../../components/QueryHandlerBase';

interface State {
  fullName?: string,
}

export default function CoachHome() {
  const dispatch = useAppDispatch();
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({});
  const {fullName} = state;

  const {loading, error, data} = useQuery(gql`
    query Query ($magicUrl: String!) {
      coachByMagicUrl (magicUrl: $magicUrl) {
        id
        fullName
      }
    }
  `, {variables: {magicUrl}});

  useEffect(() => {
    !fullName && setState({
      ...state,
      fullName: data?.coachByMagicUrl?.fullName
    });
  }, [data, state, fullName]);

  useEffect(() => {
    dispatch(updatePageTitle('Coach Portal'));
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col gap-4'}>
        <h1>Hello, {fullName}</h1>
      </div>
    </QueryHandlerBase>
  );
}