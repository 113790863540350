import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {gql, useQuery} from '@apollo/client';
import {StudentForStudentSelfWithApplications} from '../../models/StudentX';
import QueryHandlerBase from '../../components/QueryHandlerBase';
import {updatePageTitle} from '../../store/appSlice';
import ApplicationState from '../../components/ApplicationState';
import {ApplicationStateEnum} from '../../enums/Enums';
import ConfirmParticipation from '../../components/ConfirmParticipation';

type State = {
  student?: StudentForStudentSelfWithApplications,
}

export default function StudentHome() {
  const {magicUrl} = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const [state, setState] = useState<State>({});

  const {student} = state;

  const {loading, error, data, refetch} = useQuery(gql`
    query Query ($magicUrl: String!) {
      studentGetStudent (magicUrl: $magicUrl) {
        id
        firstName
        lastName
        applications {
          id
          state
          batch {
            id
            semester
            batchNumber
            acceptDeadline
          }
        }
      }
    }
  `, {variables: {magicUrl: magicUrl}, fetchPolicy: 'network-only'});

  const updateState = (obj: Partial<State>) => {
    setState((state) => ({...state, ...obj}));
  };

  useEffect(() => {
    updateState({student: data?.studentGetStudent});
  }, [data]);

  useEffect(() => {
    dispatch(updatePageTitle('Application Portal'));
  }, [dispatch]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <div className={'flex flex-col gap-4'}>
        {
          student &&
          <>
            <h1>Hello, {student.firstName} {student.lastName}</h1>
            {
              student.applications &&
              <>
                <h2>Your application</h2>
                <div className={'flex gap-4 items-center flex-wrap'}>
                  <h3 className={'mb-0.5'}>Batch {student.applications[0].batch.batchNumber} ({student.applications[0].batch.semester})</h3>
                  <div>
                    <ApplicationState label={student.applications[0].state} className={'text-xs'}/>
                  </div>
                </div>
                {
                  student.applications && student.applications[0].state === ApplicationStateEnum.ACCEPT && new Date() < new Date(student.applications[0].batch.acceptDeadline) &&
                  <>
                    <ConfirmParticipation name={student.firstName} batch={student.applications[0].batch} application={student.applications[0]} onSuccess={refetch}/>
                  </>
                }
              </>
            }
          </>
        }
      </div>
    </QueryHandlerBase>
  );
}