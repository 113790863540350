import {Container, DisplayApplication} from "./CoachVoteTypes";
import {SortableContext, useSortable} from "@dnd-kit/sortable";
import ApplicationCard from "./ApplicationCard";
import {useEffect, useMemo} from "react";

interface Props{
    container: Container;
    applications: DisplayApplication[]
    filter: string
    writeComment(application:DisplayApplication): void
}

export default function CoachVoteContainer(props: Props){
    const {
        container,
        applications,
        filter,
        writeComment,
    } = props;

    const applicationIds = useMemo(()=> {
        return applications.map((app) => app.id);
    },[applications])

    useEffect(() => {
    }, [applications]);

    const {
        setNodeRef
    } = useSortable({
        id: container.id,
        data: {
            type: "Container",
            container: container,
        }
    });
    return (
        <div ref={setNodeRef} className={'w-[500px] border-4 min-h-[200px] rounded-md'}>
            {/* Title Container*/}
            <div
                className=" text-md h-[50px] p-3 font-bold border-b-4">
                {container.title}
            </div>
            {/* Application Container*/}
            <div className={`flex flex-col gap-4 p-2 overflow-x-hidden overflow-y-auto ${container.id>0 ? 'max-h-[350px]' : 'max-h-[1165px]'}`}>
                <SortableContext items={applicationIds}>
                    {applications.filter(app => {
                        if(filter==="all")
                            return false;
                        return filter === app.primaryRole;
                        }
                    ).map((application) => (
                        <ApplicationCard key={application.id} application={application} writeComment={writeComment} ></ApplicationCard>
                       ))}
                </SortableContext>
            </div>
        </div>);
}