import React, {useCallback, useEffect, useState} from 'react';
import Application from '../../models/Application';
import {uploadSingleFile} from "../../services/UploadService";
import Student from "../../models/Student";

interface ApplicationFormFileUploaderProps {
  file?: File,
  fileType: string,
  application?: Partial<Application>,
  student?: Partial<Student>,
}

interface UploadStatus {
  file?: boolean,
}

interface State {
  uploadStatus?: UploadStatus,
}

export default function ApplicationMissingFileUploader({file, fileType, application, student}: ApplicationFormFileUploaderProps) {

  const [state, setState] = useState<State>({});
  const {uploadStatus} = state;
  const updateUploadStatus = (o: Partial<UploadStatus>) => {
    setState((state) => ({...state, uploadStatus: {...state.uploadStatus, ...o}}));
  };

  const uploadAllFiles = useCallback(() => {
    const id = fileType==='profile'? student?.id : application?.id;

    uploadSingleFile(fileType, file, {id: id || ''}, `student/${student?.magicUrl}`).then(() => {
        updateUploadStatus({file: true});
      }).catch(() => {
        updateUploadStatus({file: false});
      });
  }, [file, application, student, fileType]);

  useEffect(() => {
    uploadAllFiles();
  }, [uploadAllFiles]);

  return (
      <div className={'flex flex-col gap-4 text-center'}>
        {
          uploadStatus && Object.values(uploadStatus).includes(undefined) &&
          <>
            <h2>Uploading files...</h2>
            <p>Please do not leave/refresh this page.</p>
          </>
        }
        {
          uploadStatus && !Object.values(uploadStatus).includes(undefined) &&
          <>
            <p>File Uploaded please Refresh the page to see if it was succesfull</p>
          </>
        }
      </div>
  );
}