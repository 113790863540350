import React, {useEffect, useState} from 'react';
import {BatchForCoach} from '../../../models/BatchX';
import {gql, useQuery} from '@apollo/client';
import {useAppSelector} from '../../../hooks/hooks';
import {stringifyDate} from '../../../helpers/helper';
import QueryHandlerBase from '../../../components/QueryHandlerBase';

type coachBatchesDetailProp = {
  batchId: number,
}

type State = {
  batch?: BatchForCoach,
}

export default function CoachBatchesDetail({batchId}: coachBatchesDetailProp) {
  const {magicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({});
  const updateState = (obj: Partial<State>) => setState((state) => ({...state, ...obj}));
  const {batch} = state;

  const {loading, data, error} = useQuery(gql`
  query Query ($magicUrl: String!, $batchId: Int!) {
    coachByMagicUrl (magicUrl:$magicUrl) {
      id
      coachRoles (batchId: $batchId) {
        batch {
          id
          registrationStart
          registrationEnd
          voteStart
          voteEnd
          acceptDeadline
        }
      }
    }
  }
  `, {variables: {magicUrl, batchId}});

  useEffect(() => {
    updateState({batch: data?.coachByMagicUrl?.coachRoles[0]?.batch});
  }, [data]);

  return (
    <QueryHandlerBase loading={loading} error={error}>
      <>
        <h2>Details</h2>
        <div className={'flex flex-col gap-0'}>
          <h6>Vote start</h6>
          <p>{stringifyDate(batch?.voteStart || 0, {second: '2-digit'})}</p>
          <h6>Vote end</h6>
          <p>{stringifyDate(batch?.voteEnd || 0, {second: '2-digit'})}</p>
          <h6>Registration start</h6>
          <p>{stringifyDate(batch?.registrationStart || 0, {second: '2-digit'})}</p>
          <h6>Registration end</h6>
          <p>{stringifyDate(batch?.registrationEnd || 0, {second: '2-digit'})}</p>
          <h6>Deadline to confirm participation</h6>
          <p>{stringifyDate(batch?.acceptDeadline || 0, {second: '2-digit'})}</p>
        </div>
      </>
    </QueryHandlerBase>
  );
}