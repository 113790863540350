import React, {useEffect, useState} from 'react';
import Sidebar from '../../components/Sidebar';
import SidebarOptionLink from '../../components/SidebarOptionLink';
import {Outlet, useLocation, useParams} from 'react-router-dom';
import Header from '../../components/Header';
import OutletWithSidebar from '../../components/OutletWithSidebar';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {gql, useLazyQuery} from '@apollo/client';
import {updateMagicUrl, updatePageTitle} from '../../store/appSlice';
import FillHeightWrapper from '../../components/FillHeightWrapper';
import LoadWheel from '../../components/LoadWheel';
import {PageRoutingEnum} from '../../enums/Enums';
import LoginRecoverContent from '../../components/LoginRecoverContent';

interface State  {
  isAdmin?: boolean,
}

export default function CoachOutlet() {
  const path = useLocation().pathname.split('/');
  const {magicUrl} = useParams();
  const dispatch = useAppDispatch();
  const {magicUrl: storeMagicUrl} = useAppSelector((state) => state.app);
  const [state, setState] = useState<State>({});
  const {isAdmin} = state;

  const [getQuery, {loading, error}] = useLazyQuery(gql`
    query Query ($magicUrl: String!) {
      coachByMagicUrl (magicUrl: $magicUrl) {
        id
        coachRoles (isAdmin: true) {
          id
        }
      }
    }
  `);

  useEffect(() => {
    magicUrl && getQuery({variables: {magicUrl: magicUrl}}).then((res) => {
      dispatch(updateMagicUrl(res.data?.coachByMagicUrl ? magicUrl : null));
      isAdmin === undefined && setState({...state, isAdmin: (res.data?.coachByMagicUrl?.coachRoles?.length || 0) > 0});
    });
  }, [magicUrl, dispatch, getQuery, state, isAdmin]);

  const navItems = (
    <>
      <SidebarOptionLink label={'Home'} to={PageRoutingEnum.COACH_HOME} isActive={[...path].length === 3}/>
      <SidebarOptionLink label={'Overview'} to={PageRoutingEnum.COACH_OVERVIEW} isActive={[...path].pop() === PageRoutingEnum.COACH_OVERVIEW}/>
      <SidebarOptionLink label={'Applications'} to={PageRoutingEnum.COACH_APPLICATIONS} isActive={[...path].pop() === PageRoutingEnum.COACH_APPLICATIONS}/>
      <SidebarOptionLink label={'Coach Voting'} to={PageRoutingEnum.COACH_VOTING} isActive={[...path].pop() === PageRoutingEnum.COACH_VOTING}/>
      {
        isAdmin && <SidebarOptionLink label={'Batch Administration'} to={PageRoutingEnum.COACH_BATCH_ADMIN} isActive={[...path].pop() === PageRoutingEnum.COACH_BATCH_ADMIN}/>
      }
    </>
  );

  const sidebar = (<Sidebar>{navItems}</Sidebar>);

  const outlet = (
    <>
      {
        storeMagicUrl && <Outlet/>
      }
      {
        ((magicUrl && storeMagicUrl === null) || !magicUrl) && <LoginRecoverContent forCoach/>
      }
    </>
  );

  useEffect(() => {
    dispatch(updatePageTitle('Coach Portal'));
  }, [dispatch]);

  const header = (
    <Header to={PageRoutingEnum.PUBLIC_OUTLET} navItems={navItems} headerBackground={'#032946'} menuBackground={'#06497e'}>
      <a href={`/${PageRoutingEnum.COACH_OUTLET}/${magicUrl}`}>
        <span>COACH PORTAL</span>
      </a>
    </Header>
  );

  const loadScreen = (
    <FillHeightWrapper verticalWidthUnwrap centerVertically>
      <LoadWheel/>
    </FillHeightWrapper>
  );

  return (
    <OutletWithSidebar loading={loading} loadScreen={loadScreen} error={error}
                       header={header} outlet={outlet} sidebar={(storeMagicUrl || undefined) && sidebar}/>
  );
}